import { Button, HSpacer, ProgressLoader, Text, Toolbar, VSpacer } from '@/components/DesignSystem';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { DateRangeModal } from '@/components/shared/DateRangeModal';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import {
  ScheduledNotificationCard,
} from '@/pages/Admin/ManageNotifications/ScheduledNotificationCard';
import {
  NotificationInputs,
  NotificationModal,
} from '@/pages/Admin/ManageNotifications/NotificationModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ScheduledNotificationApi } from '@/utilities/api/ScheduledNotificationApi';
import Add from '@mui/icons-material/Add';
import { Container, Pagination, Stack } from '@mui/material';
import { pick } from '@shared/utilities';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRetailerLocationFilter } from "@/hooks/useRetailerLocationFilter";
import { ScheduledNotificationType } from '@shared/enums/ScheduledNotificationType';

export const ManageNotifications = () => {
  const { setSearch, debouncedSearch } = useSearch(1);

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());
  const [customDateRange, setCustomDateRange]
    = useState<{ startDate: Date, endDate: Date } | undefined>();
  const [showCustomDateRangeModal, setShowCustomDateRangeModal] = useState(false);
  const [showCustomNotificationModal, setShowCustomNotificationModal] = useState(false);
  const [showScheduleNotificationModal, setShowScheduleNotificationModal] = useState(false);

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    setPage(0);
  }, [selectionsString, debouncedSearch]);

  useEffect(() => {
    if (filterSelections?.get('send-date')?.has('custom') && !customDateRange) {
      setShowCustomDateRangeModal(true);
    } else if (!filterSelections?.get('send-date')?.has('custom') && customDateRange) {
      setCustomDateRange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsString]);

  const formatDate = (date: Date) => {
    const luxonDate = DateTime.fromJSDate(date);
    return luxonDate.toFormat('MMM d, y');
  };

  const retailerLocationFilter = useRetailerLocationFilter();
  const filters: Filter[] = [
    {
      id: 'send-date',
      label: 'Send date',
      options: [
        { id: '7', label: 'Older than a week' },
        { id: '180', label: 'Older than 6 months' },
        { id: '365', label: 'Older than a year' },
        {
          id: 'custom',
          label: 'Custom range',
          subline: customDateRange
            ? `${formatDate(customDateRange.startDate)} - ${formatDate(customDateRange.endDate)}`
            : undefined,
        },
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'status',
      label: 'Status',
      options: [
        { id: 'pending', label: 'Pending' },
        { id: 'sent', label: 'Sent' },
      ],
      selectionMethod: 'single-select',
    },
    retailerLocationFilter,
  ];

  const status = filterSelections?.get('status')?.values().next().value;
  const sendDate = filterSelections?.get('send-date')?.values().next().value;
  const [startSendDate, endSendDate] = useMemo(() => {
    if (sendDate === 'custom' && customDateRange) {
      return [customDateRange.startDate, customDateRange.endDate];
    } else if (sendDate && sendDate !== 'custom') {
      const daysOld = parseInt(sendDate);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - daysOld);
      return [undefined, endDate];
    }
    return [undefined, undefined];
  }, [customDateRange, sendDate]);
  const locationIds = Array.from(filterSelections?.get(retailerLocationFilter.id) ?? []);

  const { data: notifications, isFetching } = useQuery(
    [
      QueryKeys.GET_SCHEDULED_NOTIFICATIONS,
      debouncedSearch, selectionsString, customDateRange, page,
    ],
    async () => ScheduledNotificationApi.list({
      endSendDate,
      limit: 20,
      locationIds: locationIds.length ? locationIds : undefined,
      page,
      search: debouncedSearch,
      startSendDate,
      status,
    }),
  );

  const onCreateNotification = async (inputs: NotificationInputs) => {
    if (inputs.type === ScheduledNotificationType.Custom) {
      await createNotification({
        ...pick(inputs, ['message', 'sendDate', 'title']),
        recipientIds: inputs.farmerIds.concat(inputs.salespersonIds),
        type: inputs.type as ScheduledNotificationType.Custom,
      });
    } else if (inputs.type === ScheduledNotificationType.RewardsBalance) {
      await createNotification({
        ...pick(inputs, ['options', 'retailerIds', 'sendDate']),
        type: inputs.type as ScheduledNotificationType.RewardsBalance,
      });
    }
  };

  const { mutateAsync: createNotification } = useMutation(
    ScheduledNotificationApi.create,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_SCHEDULED_NOTIFICATIONS);
        openSnackbar('Custom notification created successfully');
      },
      onError: () => {
        openSnackbar('Failed to create custom notification');
      },
    },
  );

  const Header = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Text category="title-large">
          Manage Notifications
        </Text>
        <Stack direction="row">
          <Button
            onClick={() => setShowScheduleNotificationModal(true)}
            startIcon={<Add />}
            testID="schedule-notification-button"
          >
            Schedule Notification
          </Button>
          <HSpacer size="5" />
          <Button
            onClick={() => setShowCustomNotificationModal(true)}
            startIcon={<Add />}
            testID="create-notification-button"
          >
            Create Notification
          </Button>
        </Stack>
      </Stack>
      <VSpacer size="5" />
      <Stack alignItems="end" direction="row" justifyContent="space-between">
        <Toolbar
          filters={filters}
          initialSelections={filterSelections}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          showClearAllButton
          testID="notifications-toolbar"
          totalItems={notifications?.total}
          totalUnit="notification"
        />
      </Stack>
    </>
  );

  const NotificationCards = !!notifications?.data.length && (
    <Stack gap="16px">
      {notifications.data.map((notification) => (
        <ScheduledNotificationCard key={notification.id} notification={notification} />
      ))}
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <VSpacer size="10" />
        {Header}
        <VSpacer size="6" />
        {isFetching? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : NotificationCards}
        {!!notifications?.total && notifications?.lastPage > 0 && (
          <Stack alignItems="center" py="20px">
            <Pagination
              count={notifications?.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={notifications?.page + 1}
            />
          </Stack>
        )}
      </Container>
      {showCustomDateRangeModal && (
        <DateRangeModal
          acceptButtonLabel="Apply"
          cancelButton={undefined}
          disableBackdropClick
          onClose={() => setShowCustomDateRangeModal(false)}
          onConfirm={(startDate, endDate) => {
            setCustomDateRange({ startDate, endDate });
            setShowCustomDateRangeModal(false);
          }}
          open
          testID="custom-date-range-modal"
          title="Custom range"
        />
      )}
      {showCustomNotificationModal && (
        <NotificationModal
          onClose={() => setShowCustomNotificationModal(false)}
          onSubmit={onCreateNotification}
          testID="create-notification-modal"
          type={ScheduledNotificationType.Custom}
        />
      )}
      {showScheduleNotificationModal && (
        <NotificationModal
          onClose={() => setShowScheduleNotificationModal(false)}
          onSubmit={onCreateNotification}
          testID="create-notification-modal"
          type={ScheduledNotificationType.RewardsBalance}
        />
      )}
    </>
  );
};
