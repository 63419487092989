import { Button, Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { EditOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import Star from '@mui/icons-material/Star';
import { RewardsModal } from './RewardsModal';

type ProductListData = {
  externalId: string | null,
  id: string,
  manufacturerName?: string,
  name: string
}

interface ProductListItemProps {
  getoneDataList: (id: string) => void,
  handleEdit: (id: string) => void,
  isEditable?: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void,
  productListData: ProductListData,
  promotions: ApiPromotion[],
  setIsEditProductField: (isEdited: boolean) => void,
}

const ProductListItem = ({
  getoneDataList,
  handleEdit,
  isEditable,
  onClick,
  productListData,
  promotions,
  setIsEditProductField,
}: ProductListItemProps) => {
  const { externalId, id, manufacturerName, name } = productListData;
  const [showRewardsModal, setShowRewardsModal] = useState(false);
  return (
    <>
      <Card
        onClick={(e) => {
          onClick(e);
          getoneDataList(id);
        }}
        sx={{ cursor: 'pointer' }}
        testID="product-list-item"
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            {!!manufacturerName && (
              <>
                <Text
                  category="overline"
                  sx={{ color:'#C9C6BE' }}
                  testID="product-manufacturer-name"
                >
                  {manufacturerName.toUpperCase()}
                </Text>
                <VSpacer size="2" />
              </>
            )}
            <Text category="title-medium" testID="product-name" >
              {name}
            </Text>
            <VSpacer size="1" />
            <Text category="body-small" testID="product-id" >
              {externalId ? `ERP ID: ${externalId}` : `ID: ${id}`}
            </Text>
          </Stack>
          <Stack
            alignItems={isEditable ? 'center' : 'flex-start'}
            direction="row"
            gap="24px"
          >
            {!!promotions.length && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRewardsModal(true);
                }}
                size="small"
                square
                sx={{ backgroundColor: '#FFE552', height: '24px', pl: '8px', pr: '6px' }}
                testID="rewards-button"
                variant="contained"
              >
                <Text category="label-small" lineHeight={1}>
                  Rewards
                </Text>
                <HSpacer size="2"/>
                <Star sx={{ height: '12px', width: '12px' }}/>
              </Button>
            )}
            {isEditable && (
              <Button
                color="inherit"
                onClick={(e) => {
                  e.stopPropagation();
                  getoneDataList(id);
                  handleEdit(id);
                  setIsEditProductField(true);
                }}
                startIcon={<EditOutlined/>}
                sx={{ p: '10px 24px 10px 16px' }}
                testID="edit-button"
                variant="outlined"
              >
                Edit
              </Button>
            )}
          </Stack>
        </Stack>
      </Card>
      {showRewardsModal && (
        <RewardsModal
          onClose={() => setShowRewardsModal(false)}
          promotions={promotions}
        />
      )}
    </>
  );
};
export default ProductListItem;
