import { Text } from '@/components/DesignSystem';
import { Divider, Stack } from '@mui/material';
import React from 'react';

interface PointsEarnedLineItemProps {
  label: string,
  value?: string,
}

export const PointsEarnedLineItem = ({
  label,
  value,
}: PointsEarnedLineItemProps) => {
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        py="4px"
      >
        <Text category="body-medium">
          {label}
        </Text>
        <Text category="title-small">
          {value}
        </Text>
      </Stack>
      <Divider />
    </>
  );
};
