import SearchIcon from '@mui/icons-material/Search';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';

export type SearchProps = TextFieldProps & {
  height?: number,
  onChangeText?: (text: string) => void,
  testID: string,
  width?: number | string,
};

export const Search = ({
  height,
  onChangeText,
  sx,
  testID,
  width,
  ...rest
}: SearchProps) => {
  const theme = useTheme();
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              paddingBottom: "0px",
              paddingLeft: "0px",
              width: "29px",
            }}
          >
            <SearchIcon sx={{
              height: "25.06px",
              paddingRight: "8.48px",
              width: "25.05px",
            }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            data-testid={`${testID}-cancel`}
            onClick={() => onChangeText?.("")}
            position="end"
            sx={{
              width: "27px",
            }}>
            <HighlightOff
              sx={{
                cursor: "pointer",
                height: "24px",
                width: "24px",
                visibility: rest.value ? "visible" : "hidden",
              }}
            />
          </InputAdornment>
        ),
      }}
      autoComplete="off"
      inputProps={{
        "data-testid": testID,
      }}
      onChange={(event) => onChangeText?.(event.target.value)}
      placeholder='Search'
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: "28px",
        height: height ? height : undefined,
        maxWidth: width ? width : undefined,
        minWidth: width ? width: undefined,
        ...sx,
      }}
      variant="standard"
      {...rest}>
    </TextField>
  );
};
