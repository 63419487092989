import {
  Button,
  Checkbox,
  Dialog,
  HSpacer,
  Input,
  MenuItem,
  NumericInput,
  Select,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { RewardsConstants } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import useAddRewardsByFarmerId from '@/hooks/useFarmerRewards';
import ConfirmDialog from '@/pages/Admin/HierarchyOfRetailers/ConfirmDialog';
import { OrderTransactionsApi } from "@/utilities/api/OrdersApi";
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { RewardsProgramApi } from "@/utilities/api/RewardsProgramApi";
import { UserApi } from '@/utilities/api/UserApi';
import { OrderTransactionEndpoint } from "@api/endpoints/OrderTransactionEndpoint";
import { ApiUser } from '@api/interfaces';
import { ApiOrderTransaction } from "@api/interfaces/ApiOrderTransaction";
import HighlightOff from '@mui/icons-material/HighlightOff';
import { Autocomplete, CircularProgress, Divider, Stack } from '@mui/material';
import { TransactionType } from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import { formatCurrency } from '@shared/utilities';
import { getAccountName } from '@shared/utilities/UserUtilities';
import { sortBy } from 'lodash';
import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

type UpdatePointsDialogProps = {
  farmer: ApiUser,
  setShow: Dispatch<SetStateAction<boolean>>,
  show: boolean,
}

type PointsSource = 'rewards-program' | 'promotion';

const UpdatePointsDialog = ({
  farmer,
  setShow,
  show,
}: UpdatePointsDialogProps) => {
  const [notifyFarmer, setNotifyFarmer] = useState(false);
  const [points, setPoints] = useState<number>();
  const [selectedOrder, setSelectedOrder] = useState<ApiOrderTransaction | string>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [transactionType, setTransactionType] = useState<TransactionType>();
  const [pointsSource, setPointsSource] = useState<PointsSource>();
  const [selectedPromotionId, setSelectedPromotionId] = useState<string>();

  const { addRewardsById, isAddRewardsLoading } = useAddRewardsByFarmerId(() => setShow(false));

  const { data: rewardsProgram } = useQuery(
    [QueryKeys.GET_ACTIVE_PROGRAM_FOR_RETAILER, farmer.retailerId],
    () => RewardsProgramApi.getActiveProgramForRetailer(farmer.retailerId ?? ''),
    { enabled: !!farmer.retailerId },
  );

  const { data: promotions } = useQuery(
    [QueryKeys.GET_PROMOTIONS_FOR_RETAILER, farmer.retailerId],
    async () => PromotionApi.listForRetailer({
      includeExpired: true,
      retailerId: farmer.retailerId!,
    }),
    {
      enabled: !!farmer.retailerId,
    },
  );

  const onSave = async () => {
    if (!transactionType) {
      return;
    }
    let associatedOrderTransactionId : string | undefined = undefined;

    if (typeof selectedOrder !== 'string') {
      if (selectedOrder && 'externalId' in selectedOrder) {
        associatedOrderTransactionId = selectedOrder.id;
      }
    }

    const selectedPromotion = selectedPromotionId
      ? promotions?.data.find((promotion) => promotion.id === selectedPromotionId) : undefined;
    await addRewardsById({
      allowNegativeBalance: true,
      farmerId: farmer.id,
      manufacturerId: selectedPromotion?.manufacturerId,
      notifyFarmer,
      orderTransactionId: associatedOrderTransactionId,
      points: Number(points) ?? 0,
      promotionId: selectedPromotionId,
      retailerId: pointsSource === 'rewards-program'
        ? rewardsProgram?.retailerId : selectedPromotion?.retailerId,
      rewardsProgramId: pointsSource === 'rewards-program' ? rewardsProgram?.id : undefined,
      transactionType,
    });
    setShow(false);
    setShowConfirmDialog(false);
  };

  const orderTransactionsQuery : OrderTransactionEndpoint.List.Query = {
    farmerId: farmer.id,
    limit: SharedConfig.maxPageLimit,
  };

  const { data: orderTransactions, isFetching: isFetchingOrders } = useQuery(
    [QueryKeys.GET_ORDERS, orderTransactionsQuery],
    () => OrderTransactionsApi.list(orderTransactionsQuery),
  );

  const orderOptions = [
    farmer.externalId ? 'N/A' : 'Growers',
    ...(orderTransactions?.data ?? []),
  ];

  const { data: rewards } = useQuery(
    [QueryKeys.GET_USER_LOYALTY_BALANCES, farmer.id],
    () => UserApi.getLoyaltyBalances(farmer.id),
  );

  const disableNotifyFarmer = !!farmer.retailerId;
  const isFormValid = !!points
    && !!selectedOrder
    && !!transactionType
    && ((pointsSource === 'rewards-program' && rewardsProgram)
      || (pointsSource === 'promotion' && selectedPromotionId)
    );

  const rewardPointsUpdated = (value?: number) => {
    if (value && value > 0 && !points && !disableNotifyFarmer) {
      setNotifyFarmer(true);
    } else if (notifyFarmer && value && value <= 0) {
      setNotifyFarmer(false);
    }
    setPoints(value);
  };

  const totalPoints = useMemo(() => {
    let points = rewards?.growersBalance?.points ?? 0;
    Object.values(rewards?.subledgerBalances ?? {}).forEach((subledger) => {
      points += subledger.points ?? 0;
    });
    return points;
  }, [rewards]);

  return (
    <Dialog
      acceptButton={() => (
        <Button
          disabled={!isFormValid}
          loading={isAddRewardsLoading}
          onClick={() => setShowConfirmDialog(true)}
          testID='save-rewards-button'
          variant='contained'
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          onClick={() => setShow(false)}
          {...props}
          color='inherit'
          testID="rewards-cancel-button"
        >
          Cancel
        </Button>
      )}
      dialogWidth="500px"
      onClose={() => setShow(false)}
      open={show}
      showCloseButton={false}
      testID="add-rewards-dialog"
      title={RewardsConstants?.addRewardsDialogTitle}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack maxWidth="60%">
          <Text category="label-medium">
            Customer account
          </Text>
          <Text category="body-medium">
            {getAccountName(farmer)}
          </Text>
        </Stack>
        <Stack bgcolor="white" borderRadius="2px" p="8px 12px">
          <Text category="title-small" sx={{ color: '#141414' }} textAlign="right">
            Total points: {formatCurrency(totalPoints, '')}
          </Text>
        </Stack>
      </Stack>
      <VSpacer size="7" />
      <Autocomplete
        clearIcon={<HighlightOff />}
        disablePortal
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if ('publicId' in option) {
            return `Request #${option.publicId}`;
          }
          return `Order #${option.externalId}`;
        }}
        loading={isFetchingOrders}
        onChange={(_, value) => {
          setSelectedOrder(value ?? undefined);
        }}
        options={orderOptions}
        renderInput={(params) => (
          <Input
            label='Select order'
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isFetchingOrders ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            required
            testID="order-autocomplete-input"
          />
        )}
      />
      <VSpacer size="7" />
      <Select
        label="Points source"
        onChangeValue={(value) => setPointsSource(value as PointsSource)}
        required
        testID="points-source-select"
        value={pointsSource}
      >
        {!!rewardsProgram && (
          <MenuItem
            testID="rewards-program-points-source-option"
            value="rewards-program"
          >
            Rewards program loyalty points
          </MenuItem>
        )}
        <MenuItem
          testID="promotion-points-source-option"
          value="promotion"
        >
          Promotion loyalty points
        </MenuItem>
      </Select>
      {pointsSource === 'promotion' && (
        <>
          <VSpacer size="7" />
          <Select
            label="Promotion"
            onChangeValue={setSelectedPromotionId}
            required
            testID="promotion-select"
            value={selectedPromotionId}
          >
            {sortBy(promotions?.data ?? [], 'name').map((promotion) => (
              <MenuItem
                key={promotion.id}
                testID={`${promotion.name}-option`}
                value={promotion.id}
              >
                {promotion.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      <VSpacer size="4" />
      <Divider />
      <VSpacer size="7" />
      <Text category="body-xlarge">
        Enter value
      </Text>
      <Stack direction="row" justifyContent="space-between">
        <Select
          label="Type"
          onChangeValue={(transactionType) => {
            setTransactionType(transactionType as TransactionType);
          }}
          required
          testID="transaction-type-select"
          value={transactionType}
        >
          {Object.values(TransactionType).sort().map((option: string) => (
            <MenuItem
              key={option}
              testID={`${option}-transaction-type`}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
        <HSpacer size="5" />
        <NumericInput
          maxValue={9999.99}
          minValue={0}
          onChangeNumber={rewardPointsUpdated}
          placeholder="0.00"
          required
          testID="add-rewards-points-input"
          value={points}
          width="220px"
        />
      </Stack>
      <VSpacer size="5" />
      <Checkbox
        checked={notifyFarmer}
        disabled={disableNotifyFarmer}
        onChangeChecked={setNotifyFarmer}
        testID="rewards-notify-farmer"
      >
        <Text disabled={disableNotifyFarmer}>Notify customer</Text>
      </Checkbox>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        message={RewardsConstants.addRewardsConfirmation}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={onSave}
        title="Add/remove reward points?"
      />
    </Dialog>
  );
};

export default UpdatePointsDialog;
