import { Text, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { PointsEarnedLineItem } from '@/pages/CustomerDashboard/Benefits/PointsEarnedLineItem';
import { ElevatedCard } from '@/pages/CustomerDashboard/ElevatedCard';
import { getRewardsValueText } from '@/pages/CustomerDashboard/helpers';
import { ResolvedTier } from '@api/data/RewardsProgramData';
import { ApiCategory, ApiPromotion, ApiRetailer } from '@api/interfaces';
import { Divider, Stack } from '@mui/material';
import React, { Fragment, useMemo } from 'react';

interface ProgramPointsEarnedCardProps {
  categories: ApiCategory[],
  retailer: ApiRetailer,
  retailerPromotions: ApiPromotion[],
  testID: string,
  tier: ResolvedTier,
}

export const ProgramPointsEarnedCard = ({
  categories,
  retailer,
  retailerPromotions,
  testID,
  tier,
}: ProgramPointsEarnedCardProps) => {
  const imageUrl = retailer?.image && `${AppConfig.staticImageHost}/${retailer.image}`;

  const sortByCategoryName = (
    categoryIdA: string,
    categoryIdB: string,
  ): number => {
    const categoryA = categories.find((category) => category.id === categoryIdA);
    const categoryB = categories.find((category) => category.id === categoryIdB);
    return (categoryA?.name ?? '').localeCompare(categoryB?.name ?? '');
  };

  const sortedSegments = useMemo(() => (
    tier.segments.filter(
      (segment) => (
        !segment.hideOnDashboard
        && (!segment.subcategoryRewards || (
          segment.subcategoryRewards
          && Object.values(segment.subcategoryRewards).some((reward) => !reward.hideOnDashboard)
        ))
      ),
    ).sort(
      ({ categoryId: categoryIdA }, { categoryId: categoryIdB }) => (
        sortByCategoryName(categoryIdA, categoryIdB)
      ),
    )
  ), [categories, sortByCategoryName, tier]);

  const sortedRetailerPromotions = retailerPromotions.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <ElevatedCard
      imageUrl={imageUrl}
      testID={testID}
      title={retailer.name}
    >
      <Stack>
        <Divider />
        {sortedSegments.map((segment) => {
          const { categoryId, rewardsType, rewardsValue, uom } = segment;
          const categoryName = categories.find(({ id }) => id === categoryId)!.name;
          if (segment.subcategoryRewards) {
            return (
              <Stack key={categoryId}>
                <PointsEarnedLineItem label={categoryName} />
                <Stack pl="16px">
                  {Object.entries(segment.subcategoryRewards).filter(
                    ([_, subcatRewards]) => !subcatRewards.hideOnDashboard,
                  ).sort(
                    ([subcatIdA], [subcatIdB]) => sortByCategoryName(subcatIdA, subcatIdB),
                  ).map(([subcatId, subcatRewards], index) => (
                    <Fragment key={subcatId}>
                      <PointsEarnedLineItem
                        label={categories.find(({ id }) => id === subcatId)!.name}
                        value={getRewardsValueText(
                          subcatRewards.rewardsType,
                          subcatRewards.rewardsValue,
                          uom,
                        )}
                      />
                      {index === (Object.entries(segment.subcategoryRewards!).length - 1) &&  (
                        <VSpacer size="3" />
                      )}
                    </Fragment>
                  ))}
                </Stack>
              </Stack>
            );
          } else {
            return (
              <>
                <Stack
                  alignItems="center"
                  direction="row"
                  height="28px"
                  justifyContent="space-between"
                  key={categoryId}
                  py="4px"
                >
                  <Text category="body-medium">
                    {categoryName}
                  </Text>
                  <Text category="title-small">
                    {getRewardsValueText(rewardsType, rewardsValue!, uom)}
                  </Text>
                </Stack>
                <Divider />
              </>
            );
          }
        })}
        {sortedRetailerPromotions.map((promotion) => (
          <PointsEarnedLineItem
            key={promotion.id}
            label={promotion.name}
            value={getRewardsValueText(promotion.rewardType, promotion.value, promotion.uom)}
          />
        ))}
      </Stack>
    </ElevatedCard>
  );
};
