import { Text, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { formatDate } from '@/pages/CustomerDashboard/helpers';
import { PointsTag } from '@/components/shared/PointsTag';
import { PromotionApi } from '@/utilities/api/PromotionApi';
import { Avatar, CircularProgress, Divider, Stack } from '@mui/material';
import { SponsorType } from '@shared/enums';
import { getJsDateFromString } from '@shared/utilities';
import React from 'react';
import { useQuery } from 'react-query';

interface PromotionDetailsModalProps {
  accessToken: string,
  onClose: () => void,
  promotionId: string,
  retailerName: string,
}

export const PromotionDetailsModal = ({
  accessToken,
  onClose,
  promotionId,
  retailerName,
}: PromotionDetailsModalProps) => {
  const { data: promotion, isFetching } = useQuery(
    [QueryKeys.GET_PROMOTION, promotionId],
    () => PromotionApi.get(promotionId, accessToken),
  );
  const productEarnNames = (promotion?.productsForEarningPoints ?? []).map(
    ({ name }) => name,
  ).sort();
  const productRedeemNames = (promotion?.productsForRedeemingPoints ?? []).map(
    ({ name }) => name,
  ).sort();
  const manufacturerName = promotion?.manufacturer?.name ?? '';
  const manufacturerLogoSrc = promotion?.manufacturer?.logo
    && `${AppConfig.staticImageHost}/${promotion.manufacturer.logo}`;
  const locationNames = (promotion?.locations ?? []).map(({ name }) => name).sort();
  const formattedStartDate = promotion && formatDate(getJsDateFromString(promotion.startDate));
  const formattedEndDate = promotion && formatDate(getJsDateFromString(promotion.endDate));

  const getProductRedeemText = () => {
    if (!promotion) {
      return '';
    }
    if (productRedeemNames.length) {
      return productRedeemNames.join(', ');
    }
    if (promotion.sponsorType === SponsorType.Retailer) {
      return `All products sold by ${retailerName}`;
    }
    return `All ${manufacturerName} products sold by ${retailerName}`;
  };

  return (
    <DashboardModal
      onClose={onClose}
      open
      testID="promotion-details-modal"
      title="Promotion details"
    >
      {isFetching ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stack alignItems="center" bgcolor="#393939" p="8px 6px">
            <Text category="label-medium" sx={{ color: 'white' }}>
              Runs {formattedStartDate} - {formattedEndDate}
            </Text>
          </Stack>
          {promotion?.sponsorType === SponsorType.Manufacturer && (
            <>
              <Stack alignItems="center" p="20px 16px">
                {!!manufacturerLogoSrc && (
                  <>
                    <Avatar
                      alt={`${manufacturerName} logo`}
                      src={manufacturerLogoSrc}
                      sx={{ height: '48px', width: '48px' }}
                    />
                    <VSpacer size="2" />
                  </>
                )}
                <Text category="headline-medium" textAlign="center">
                  {manufacturerName}
                </Text>
                <VSpacer size="2" />
                <Text category="overline">
                  MANUFACTURER PROMOTION
                </Text>
              </Stack>
              <Divider />
            </>
          )}
          <Stack px="16px">
            <Stack pb="20px" pt="24px">
              <Text category="title-large" fontWeight={500} px="16px" textAlign="center">
                {promotion?.name}
              </Text>
              <VSpacer size="2"/>
              <PointsTag
                points={promotion?.value ?? 0}
                rewardsType={promotion?.rewardType}
                starHeight="20px"
                starWidth="20px"
                textCategory="body-large"
                uom={promotion?.uom}
              />
              {promotion?.overridePointsEngine && (
                <>
                  <VSpacer size="4"/>
                  <Stack bgcolor="#f7f7f7" p="8px 12px">
                    <Text category="body-xsmall">
                      Only manufacturer promotion points will be granted. Retailer rewards
                      program points are not granted for the products included in this promotion.
                    </Text>
                  </Stack>
                </>
              )}
            </Stack>
            <Divider />
            {!!promotion?.description && (
              <>
                <Stack py="16px">
                  <Text category="title-medium">
                    Promotion information
                  </Text>
                  <Text category="body-medium" sx={{ color: '#525252' }}>
                    {promotion?.description}
                  </Text>
                </Stack>
                <Divider />
              </>
            )}
            <VSpacer size="6"/>
            <Stack py="16px">
              <Text category="title-medium">
                Participating {retailerName} locations
              </Text>
              <VSpacer size="3" />
              <Text category="body-medium" sx={{ color: '#525252' }}>
                {locationNames.join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack py="16px">
              <Text category="title-medium">
                Products that you can earn points on
              </Text>
              <VSpacer size="3" />
              <Text category="body-medium" sx={{ color: '#525252' }}>
                {productEarnNames.join(', ')}
              </Text>
            </Stack>
            <Divider />
            <Stack py="16px">
              <Text category="title-medium">
                Products that points can be redeemed on
              </Text>
              <VSpacer size="3" />
              <Text category="body-medium" sx={{ color: '#525252' }}>
                {getProductRedeemText()}
              </Text>
            </Stack>
          </Stack>
        </>
      )}
    </DashboardModal>
  );
};
