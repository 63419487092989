import {
  Button,
  Checkbox,
  DatePickerDropdown,
  HSpacer,
  InfoTooltip,
  Input,
  Radio,
  Select,
  Text,
  VSpacer,
} from "@/components/DesignSystem";
import React from "react";
import { CircularProgress, Container, MenuItem, RadioGroup, Stack } from "@mui/material";
import { PromotionInputs } from "@/pages/Admin/Promotions/PromotionModal/PromotionModal";
import { useGetRetailerList, useManufacturerList } from "@/hooks/useProductQuery";
import Edit from "@mui/icons-material/Edit";
import { SponsorSelector } from "@/pages/Admin/Promotions/PromotionModal/SponsorSelector";
import { SponsorType } from "@shared/enums";
import { ApiRetailer, ApiRetailerLocation } from "@api/interfaces";
import { EditLocationsModal } from "@/pages/Admin/Promotions/PromotionModal/EditLocationsModal";
import { getSelectedLocationsText } from "@/pages/Admin/Promotions/PromotionModal/Helpers";
import { isBoolean } from "lodash";
import { ApiRewardsProgram } from "@api/interfaces/ApiRewardsProgram";

interface WorkflowStep1Props {
  activeRewardsProgram?: ApiRewardsProgram | null,
  onPromotionChanged: (promotion: PromotionInputs) => void,
  promotion: PromotionInputs,
  retailerLocations?: ApiRetailerLocation[],
  selectedRetailer?: ApiRetailer,
}

export const WorkflowStep1 = ({
  activeRewardsProgram,
  onPromotionChanged,
  promotion,
  retailerLocations,
  selectedRetailer,
}: WorkflowStep1Props) => {
  const [showEditLocationsModal, setShowEditLocationsModal] = React.useState(false);

  const { retailerList, isLoading: isRetailerListLoading } = useGetRetailerList({
    hasLocations: true,
  });

  const { manufacturerList } = useManufacturerList({
    ...(selectedRetailer && { retailerId: selectedRetailer.id }),
    ...(selectedRetailer?.hasErpProducts && { hasErpProducts: true }),
  }, {
    enabled: !!selectedRetailer && promotion.sponsorType === SponsorType.Manufacturer,
  });

  if (isRetailerListLoading) {
    return <Container>
      <Stack alignItems='center'>
        <VSpacer size='14' />
        <CircularProgress />
      </Stack>
    </Container>;
  }

  return <Stack sx={{ margin: '0 auto', maxWidth: '560px' }}>
    <Text category="title-medium">
      Promotion name
    </Text>
    <VSpacer size="4"/>
    <Input
      label="Promotion Name"
      maxCharacterLimit={75}
      onChangeText={(name) => onPromotionChanged({
        ...promotion,
        name: name ? name : undefined,
      })}
      testID="create-promotion-modal-name-id"
      value={promotion.name}
    />
    <VSpacer size="8"/>
    <Text category="title-medium">
      Running dates
    </Text>
    <VSpacer size="4"/>
    <Stack direction="row" spacing={2}>
      <DatePickerDropdown
        hideHelperText
        label="Start date"
        onChange={(date) => onPromotionChanged({ ...promotion, startDate: date })}
        testID="create-promotion-modal-start-date-id"
        value={promotion.startDate}
      />
      <DatePickerDropdown
        hideHelperText
        label="End date"
        onChange={(date) => onPromotionChanged({ ...promotion, endDate: date })}
        testID="create-promotion-modal-end-date-id"
        value={promotion.endDate}
      />
    </Stack>
    <VSpacer size="8"/>
    <Text category="title-medium">
      Retailer
    </Text>
    <VSpacer size="4"/>
    <Select
      label="Select a retailer"
      onChangeValue={(retailerID: string) => {
        onPromotionChanged({
          ...promotion,
          locationIds: [],
          productIdsForEarningPoints: undefined,
          productIdsForRedeemingPoints: undefined,
          retailerId: retailerID,
        });
      }}
      testID="create-promotion-modal-retailer-id"
      value={selectedRetailer?.id}
    >
      {retailerList?.map((retailer) => (
        <MenuItem key={retailer.id} value={retailer.id}>
          {retailer.name}
        </MenuItem>
      ))}
    </Select>
    {!!retailerLocations?.length && (
      <>
        <VSpacer size="3" />
        <Stack alignItems="center" direction="row" spacing={2}>
          <Text category="body-large">
            {getSelectedLocationsText(
              promotion.locationIds?.length ?? 0,
              retailerLocations?.length ?? 0,
            )}
          </Text>
          <Button
            color="primary"
            onClick={() => {
              setShowEditLocationsModal(true);
            }}
            startIcon={<Edit />}
            testID={`edit-retailer-locations-${selectedRetailer?.id}`}
            variant="text"
          >
            Edit
          </Button>
        </Stack>
      </>
    )}
    <VSpacer size="8"/>
    <SponsorSelector
      onChange={(sponsorType: SponsorType) => {
        const updatedPromotion = {
          ...promotion,
          sponsorType,
        };
        if (sponsorType === SponsorType.Retailer) {
          updatedPromotion.manufacturerId = '';
        } else {
          updatedPromotion.manufacturerId = undefined;
        }
        onPromotionChanged(updatedPromotion);
      }}
      value={promotion.sponsorType}
    />
    {promotion.sponsorType === SponsorType.Manufacturer && (
      <>
        <VSpacer size="7" />
        <Select
          disabled={!selectedRetailer}
          label="Select a manufacturer"
          onChangeValue={(manufacturerID: string) => {
            onPromotionChanged({
              ...promotion,
              manufacturerId: manufacturerID,
              productIdsForEarningPoints: undefined,
              productIdsForRedeemingPoints: undefined,
            });
          }}
          testID="create-promotion-modal-manufacturer-id"
          value={promotion.manufacturerId}
        >
          {manufacturerList?.map((manufacturer) => (
            <MenuItem key={manufacturer.id} value={manufacturer.id}>
              {manufacturer.name}
            </MenuItem>
          ))}
        </Select>
      </>
    )}
    <VSpacer size="8"/>
    <Text category="title-medium">
      Redeeming points
    </Text>
    <VSpacer size="4"/>
    <RadioGroup
      name="redeem-points-radio-group"
      onChange={(e) => {
        onPromotionChanged({
          ...promotion,
          redeemOnAllProducts: e.target.value === "all-products",
        });
      }}
      row
      value={isBoolean(promotion.redeemOnAllProducts)
        ? promotion.redeemOnAllProducts ? "all-products" : "select-products"
        : null
      }>
      <Radio
        testID="redeem-points-radio-option-all-products"
        value={"all-products"}
      >
        <Text category="title-medium">
          {promotion.sponsorType === SponsorType.Retailer
            ? "Points can be redeemed on any product"
            : "Points can be redeemed on any of the selected manufacturer's products"
          }
        </Text>
      </Radio>
      <VSpacer size="4"/>
      <Radio
        testID="redeem-points-radio-option-select-products"
        value={"select-products"}
      >
        <Text category="title-medium">
          {promotion.sponsorType === SponsorType.Retailer
            ? "Points can be redeemed on select products"
            : "Points can be redeemed on select products of the selected manufacturer"
          }
        </Text>
      </Radio>
    </RadioGroup>
    <VSpacer size="8"/>
    <Stack alignItems="center" direction="row">
      <Text category="title-medium">
        Customer Rewards Dashboard display (Optional)
      </Text>
      <HSpacer size="3"/>
      <InfoTooltip>
        If you want this promotion to display in one of the following locations, please make a
        selection. Otherwise, the promotion will not display in the specific locations listed below.
      </InfoTooltip>
    </Stack>
    <VSpacer size="4"/>
    <Checkbox
      alignItems="flex-start"
      checked={promotion.showInDashboardCarousel}
      onChangeChecked={(showInDashboardCarousel) => onPromotionChanged({
        ...promotion,
        showInDashboardCarousel,
      })}
      testID="display-promotion-on-carousel-checkbox-id"
    >
      <Text category="title-medium">
        Include the promotion within the 'Product Promotions' carousel on the dashboard
      </Text>
    </Checkbox>
    <VSpacer size="4"/>
    <Checkbox
      alignItems="flex-start"
      checked={promotion.showInDashboardTable}
      onChangeChecked={(showInDashboardTable) => onPromotionChanged({
        ...promotion,
        showInDashboardTable,
      })}
      testID="display-promotion-in-dashboard-table-checkbox-id"
    >
      <Text category="title-medium">
        Include the promotion within the 'point value' table on the 'Program Benefits' tier
        details page
      </Text>
    </Checkbox>
    <VSpacer size="8"/>
    {!!activeRewardsProgram?.grantBothProgramAndPromotionPoints && (
      <>
        <Text category="title-medium">
          Points engine override (Optional)
        </Text>
        <VSpacer size="4"/>
        <Checkbox
          alignItems="flex-start"
          checked={promotion.overridePointsEngine}
          onChangeChecked={(overridePointsEngine) => onPromotionChanged({
            ...promotion,
            overridePointsEngine,
          })}
          testID="override-points-engine-checkbox-id"
        >
          <Text category="title-medium">
            Only allow manufacturer promotion points to be granted. Retailer rewards program
            points are not granted for the products included in this promotion.
          </Text>
        </Checkbox>
      </>
    )}
    {!!selectedRetailer && !!retailerLocations?.length && showEditLocationsModal && (
      <EditLocationsModal
        onClose={() => setShowEditLocationsModal(false)}
        onPromotionChanged={onPromotionChanged}
        promotion={promotion}
        retailerLocations={retailerLocations}
        selectedRetailer={selectedRetailer}
      />
    )}
  </Stack>;
};