import {
  Button,
  Card,
  IconButton,
  ProgressLoader,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { QueryKeys } from "@/constants/QueryKeys";
import { PromotionStatusChip } from '@/pages/Admin/Promotions/PromotionStatusChip';
import { ManufacturerApi } from "@/utilities/api/ManufacturerApi";
import { getStatuses, PromotionStatus } from '@/utilities/api/PromotionUtilities';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { DeleteOutline } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from "react-query";

interface PromotionCardProps {
  onDelete: () => void,
  onEdit: () => void,
  onView: () => void,
  promotion: ApiPromotion,
  testID: string,
}

function formatDate (date: string) {
  return DateTime.fromISO(date).toFormat('MMM d, yyyy');
}

export const PromotionCard = ({
  onDelete,
  onEdit,
  onView,
  promotion,
  testID,
}: PromotionCardProps) => {
  const statuses = getStatuses(promotion);
  const isFuture = statuses.some(status => status === PromotionStatus.FUTURE);
  const manufacturerId = promotion.manufacturerId;

  const { data: manufacturer, isFetching } = useQuery(
    [QueryKeys.GET_MANUFACTURER_BY_ID, manufacturerId],
    () => manufacturerId ? ManufacturerApi.get(manufacturerId) : null,
    { enabled: !!manufacturerId },
  );

  if (isFetching) {
    return (
      <div
        style={{
          zIndex: 9999,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <ProgressLoader type='circular' />
      </div>
    );
  }

  const CardContent = (
    <Stack
      alignItems='top'
      direction='row'
      justifyContent={'space-between'}
    >
      <Stack>
        <Text category="overline" sx={{ color: '#D8D8D8' }}>
          {manufacturer?.name}
        </Text>
        <VSpacer size="1" />
        <Text category="body-xlarge">
          {promotion.name}
        </Text>
        <VSpacer size="1" />
        <Text category="body-medium">
          Runs {formatDate(promotion.startDate)} – {formatDate(promotion.endDate)}
        </Text>
      </Stack>
      {isFuture && (
        <Stack alignItems='top' direction='row' spacing={2}>
          <IconButton
            color="inherit"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            testID={`${testID}-delete-button`}
          >
            <DeleteOutline />
          </IconButton>
          <Button
            disabled
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
            size="medium"
            startIcon={<EditIcon />}
            sx={{ padding: '10px 20px' }}
            testID={`${testID}-view-button`}
            variant="outlined"
          >
            Edit
          </Button>
        </Stack>
      )}
    </Stack>
  );

  return (
    <Card
      cardContentStyle={{ padding: '16px', "&:last-child": { paddingBottom: "16px" } }}
      onClick={onView}
      sx={{ cursor: 'pointer' }}
      testID={testID}
    >
      {CardContent}
      <VSpacer size="3" />
      <PromotionStatusChip promotion={promotion} />
    </Card>
  );
};
