import {
  BottomSheet,
  HSpacer,
  IconButton,
  Text,
  TextBadge,
  VSpacer,
} from '@/components/DesignSystem';
import React from 'react';
import { ModalBottomSheet } from '@/components/shared/ModalBottomSheet/ModalBottomSheet';
import { ApiAccountGroupCustomer, ApiUser } from '@api/interfaces';
import { Resolved } from '@shared/utilities/UtilityTypes';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { AccountGroupCustomerRole, State, StateAbbreviations } from '@shared/enums';
import { formatPhoneNumber } from '@shared/utilities';
import { Close, FiberManualRecord, Notifications, NotificationsOff } from '@mui/icons-material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { getAccountName } from '@shared/utilities/UserUtilities';

interface LinkedAccountsModalProps {
  accounts: Resolved<ApiAccountGroupCustomer, 'customer'>[],
  farmer: ApiUser,
  onClose: () => void,
}

export const LinkedAccountsModal = ({
  accounts,
  farmer,
  onClose,
}: LinkedAccountsModalProps) => {
  const theme = useTheme();
  const [showInfoModal, setShowInfoModal] = React.useState(false);

  const secondaryAccounts = accounts.filter(({ customerRole }) => (
    customerRole === AccountGroupCustomerRole.Secondary
  ));
  const partnerAccounts = accounts.filter(({ customerRole }) => (
    customerRole === AccountGroupCustomerRole.Partner
  ));

  const InfoModal = (
    <BottomSheet
      onClose={() => setShowInfoModal(false)}
      onOpen={() => null}
      open
      pullerSx={{ bgcolor: 'black' }}
      showPuller
      style={{ backgroundColor: theme.palette.background.default }}
      testID="info-modal"
    >
      <Stack p="8px 24px">
        <Text category="body-medium">
          All linked customer accounts will share one rewards account.
          <br/>
          <br/>
          Primary and partner accounts can receive rewards balance notifications and will be able
          to access the rewards account and apply loyalty points towards purchases.
          <br/>
          <br/>
          Secondary accounts will not receive rewards balance notifications and cannot access
          the rewards account.
        </Text>
      </Stack>
    </BottomSheet>
  );

  return (
    <>
      <ModalBottomSheet
        centerTitle
        closeButton={(
          <IconButton
            onClick={onClose}
            sx={{
              marginLeft: "-8px",
              marginRight: "-2px",
            }}
            testID="close-button"
          >
            <Close sx={{ height: 24, width: 24 }} />
          </IconButton>
        )}
        containerStyle={{ p: '0px', backgroundColor: theme.palette.background.default }}
        headerStyle={{ backgroundColor: theme.palette.background.default }}
        onClose={onClose}
        open
        showFooter={false}
        testID="linked-accounts-modal"
        title="Linked accounts"
        topButtonAction={(
          <IconButton
            onClick={() => setShowInfoModal(true)}
            testID="info-button"
          >
            <InfoOutlined />
          </IconButton>
        )}
      >
        <Box px="16px">
          <Text category="body-small">
            Customer: {getAccountName(farmer)}
          </Text>
        </Box>
        {secondaryAccounts.length > 0 && (
          <>
            <VSpacer size="7" />
            <Box px="16px">
              <Text category="overline">
                SECONDARY ACCOUNTS
              </Text>
            </Box>
            <VSpacer size="5" />
            <Divider />
            <Stack divider={<Divider />}>
              {secondaryAccounts.map((account) => (
                <LinkedAccountCard
                  account={account}
                  key={account.id}
                />
              ))}
            </Stack>
            <Divider />
          </>
        )}
        {partnerAccounts.length > 0 && (
          <>
            {secondaryAccounts.length > 0 && (
              <VSpacer size="3" />
            )}
            <VSpacer size="7" />
            <Box px="16px">
              <Text category="overline">
                PARTNER ACCOUNTS
              </Text>
            </Box>
            <VSpacer size="5" />
            <Divider />
            <Stack divider={<Divider />}>
              {partnerAccounts.map((account) => (
                <LinkedAccountCard
                  account={account}
                  key={account.id}
                />
              ))}
            </Stack>
            <Divider />
            <VSpacer size="7" />
          </>
        )}
      </ModalBottomSheet>
      {showInfoModal && InfoModal}
    </>
  );
};

interface LinkedAccountCardProps {
  account: Resolved<ApiAccountGroupCustomer, 'customer'>,
}

const LinkedAccountCard = ({
  account,
}: LinkedAccountCardProps) => {
  const theme = useTheme();
  const user = account.customer;
  const accountName = getAccountName(user);
  const formattedLocation = user.city && user.state
    ? `${user.city}, ${StateAbbreviations[user.state as State] ?? user.state}`
    : null;
  const formattedPhone = user.telephone
    ? formatPhoneNumber(user.telephone, 'paren')
    : null;

  return (
    <Stack p="16px">
      {!!user.businessName && user.businessName !== accountName && (
        <Text
          fontSize="10px"
          fontWeight={500}
          letterSpacing="1px"
          sx={{ color: '#525252' }}
        >
          {user.businessName.toUpperCase()}
        </Text>
      )}
      <VSpacer size="1" />
      <Text category="title-medium">
        {accountName}
      </Text>
      <Stack
        alignItems="center"
        direction="row"
        divider={<FiberManualRecord sx={{ height: '6px', width: '6px' }} />}
        gap="8px"
      >
        {!!formattedLocation && (
          <Text category="body-medium">
            {formattedLocation}
          </Text>
        )}
        {!!formattedPhone && (
          <Text category="body-medium">
            {formattedPhone}
          </Text>
        )}
      </Stack>
      <VSpacer size="3" />
      <Stack alignItems="center" direction="row">
        <TextBadge
          backgroundColor={account.customerRole === AccountGroupCustomerRole.Secondary
            ? '#B2F2F0'
            : '#E5D1FF'
          }
          text={`${account.customerRole.toUpperCase()} ACCOUNT`}
          textColor={theme.palette.text.primary}
        />
        {account.customerRole === AccountGroupCustomerRole.Partner && (
          <>
            <HSpacer size="3" />
            {account.sendNotifications ? (
              <Notifications
                sx={{
                  color: theme.palette.grey[700],
                  height: '16px',
                  width: '16px',
                }}
              />
            ) : (
              <NotificationsOff
                sx={{
                  color: theme.palette.grey[700],
                  height: '16px',
                  width: '16px',
                }}
              />
            )}
            <HSpacer size="2" />
            <Text
              fontSize="11px"
              fontWeight={500}
              letterSpacing="1px"
              sx={{ color: theme.palette.grey[700], pt: '2px' }}
            >
              NOTIFICATIONS {account.sendNotifications ? 'ON' : 'OFF'}
            </Text>
          </>
        )}
      </Stack>
    </Stack>
  );
};
