import { HSpacer, Text } from '@/components/DesignSystem';
import { Category } from '@/themes/variant-interfaces/Category';
import Star from '@mui/icons-material/Star';
import { Stack, SxProps, Theme } from '@mui/material';
import { RewardsType } from '@shared/enums/RewardsType';
import { RewardsUom } from '@shared/enums/RewardsUom';
import React from 'react';

interface PointsTagProps {
  labelStyle?: SxProps<Theme>,
  points: number,
  rewardsType?: RewardsType,
  starHeight?: string,
  starWidth?: string,
  uom?: RewardsUom,
  textCategory?: Category,
  variant?: 'filled' | 'text'
}

const UomToSingular = {
  [RewardsUom.Acres]: 'acre',
  [RewardsUom.Bags]: 'bag',
  [RewardsUom.Bushels]: 'bushel',
  [RewardsUom.Dollars]: 'dollar',
  [RewardsUom.Gallons]: 'gallon',
  [RewardsUom.Tons]: 'ton',
};

export const PointsTag = ({
  labelStyle,
  points,
  rewardsType = RewardsType.Dollars,
  starHeight = '12px',
  starWidth = '12px',
  textCategory = 'label-small',
  uom,
  variant = 'text',
}: PointsTagProps) => {
  const displayValue = rewardsType === RewardsType.Dollars ? points : points * 100;
  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5,
  }).format(displayValue);

  const rewardsTypeString = rewardsType === RewardsType.Percent ? '%' : '';
  let text = `${formattedValue}${rewardsTypeString} point${formattedValue === '1' ? '' : 's'}`;
  if (uom) {
    if (uom === RewardsUom.Dollars) {
      text += ' per $1 spent';
    } else {
      text += ` per ${UomToSingular[uom]} purchased`;
    }
  }
  const content = (
    <Stack alignItems="center" direction="row">
      <Star
        sx={{
          color: variant === 'filled' ? '#141414' : '#F1BC42',
          height: starHeight,
          width: starWidth,
        }}
      />
      <HSpacer size="2"/>
      <Text category={textCategory} noWrap pt="2px" sx={labelStyle}>
        {text}
      </Text>
    </Stack>
  );

  if (variant === 'text') {
    return content;
  }
  return (
    <Stack
      alignItems="center"
      bgcolor="#FFDD56"
      borderRadius="2px"
      direction="row"
      padding="3px 7px 3px 5px"
      width="fit-content"
    >
      {content}
    </Stack>
  );
};
