import { CircularProgress, Divider, Stack, useTheme } from '@mui/material';
import { groupBy, sortBy } from 'lodash';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { Text, VSpacer } from '@/components/DesignSystem';
import React from 'react';
import { ResolvedOrderTransaction } from '@/pages/CustomerDashboard/interfaces';
import { RewardsActivityCard } from '@/pages/CustomerDashboard/RewardsActivity/RewardsActivityCard';
import { ApiAccountGroupCustomer, ApiUser } from '@api/interfaces';
import { Resolved } from '@shared/utilities/UtilityTypes';

interface RewardsActivityModalProps {
  farmer: ApiUser,
  linkedAccounts: Resolved<ApiAccountGroupCustomer, 'customer'>[],
  onClose: () => void,
  orders?: ResolvedOrderTransaction[],
}

export const RewardsActivityModal = ({
  farmer,
  linkedAccounts,
  onClose,
  orders,
}: RewardsActivityModalProps) => {
  const theme = useTheme();

  const ordersByYear = orders
    ? groupBy(orders, (order) => (
      order.orderDate.getFullYear()
    ))
    : undefined;

  return (
    <DashboardModal
      onClose={onClose}
      open
      testID="rewards-activity-modal"
      title="Rewards Activity"
    >
      <Stack px="16px">
        <VSpacer size="5"/>
        <Text category="body-xsmall" sx={{ color: theme.palette.grey[800] }}>
          Disclaimer: Loyalty points are not earned on miscellaneous fees, taxes, surcharges, etc.
        </Text>
        <VSpacer size="5"/>
        {!ordersByYear ? (
          <Stack alignItems="center">
            <VSpacer size="14"/>
            <CircularProgress/>
          </Stack>
        ) : (
          <Stack gap="28px">
            {Object.entries(ordersByYear).sort(
              ([yearA], [yearB]) => yearB.localeCompare(yearA),
            ).map(([key, yearOrders]) => (
              <YearActivity
                farmer={farmer}
                key={key}
                linkedAccounts={linkedAccounts}
                orders={yearOrders}
                year={Number(key)}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </DashboardModal>
  );
};

interface YearActivityProps {
  farmer: ApiUser,
  linkedAccounts: Resolved<ApiAccountGroupCustomer, 'customer'>[],
  orders: ResolvedOrderTransaction[],
  year: number,
}

const YearActivity = ({
  farmer,
  linkedAccounts,
  orders,
  year,
}: YearActivityProps) => {
  const sortedOrders = sortBy(orders, ({ orderDate }) => -1 * orderDate.getTime());

  return (
    <Stack>
      <Text category="headline-medium">
        {year}
      </Text>
      <VSpacer size="3"/>
      <Divider />
      <Stack divider={<Divider />}>
        {sortedOrders.map((order) => {
          let customer: ApiUser | undefined = undefined;
          if (linkedAccounts.length > 0) {
            customer = linkedAccounts.find((account) => (
              account.customerId === order.farmerId
            ))?.customer;
            if (!customer) {
              customer = farmer;
            }
          }
          return (
            <RewardsActivityCard
              customer={customer}
              key={order.id}
              order={order}
            />
          );
        })}
      </Stack>
      <Divider />
    </Stack>
  );
};
