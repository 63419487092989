import { GlobalSnackbarProvider } from '@/providers/GlobalSnackbarProvider';
import { ShoppingCartProvider } from '@/providers/ShoppingCartProvider';
import { OtherEventType, useLogEvent } from '@/utilities/Analytics';
import { getSubdomain, getTheme } from '@/utilities/ThemeUtilities';
import { LoggedInView } from '@/views/LoggedInView';
import { LoginView } from '@/views/LoginView';
import { StorefrontLoginView } from '@/views/StorefrontLoginView';
import { UnderConstructionView } from '@/views/UnderConstructionView';
import { CircularProgress, Container, CssBaseline, Stack } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { userIsFarmer, userIsInternal, userIsRetailer } from '@shared/utilities';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { VSpacer } from './components/DesignSystem';
import { AppConfig } from './constants/AppConfig';
import { PublicRoutes, Routes } from './constants/Routes';
import {
  AuthenticationProvider,
  SessionState,
  useAuthentication,
} from './contexts/dataSync/AuthenticationContext';
import { InternalView } from './views/InternalView';
import { PublicView } from './views/PublicView';
import { RetailerView } from './views/RetailerView';

const MainView = () => {
  const location = useLocation();

  const { sessionState, user } = useAuthentication();
  const logEvent = useLogEvent();
  const navigate = useNavigate();
  const subdomain = getSubdomain();

  useEffect(() => {
    const routesToNotRedirect = [
      Routes.MY_PROFILE,
      Routes.CUSTOMER_DASHBOARD,
      Routes.CUSTOMER_DASHBOARD_ALT,
      Routes.CUSTOMER_DASHBOARD_BENEFITS,
      Routes.CUSTOMER_DASHBOARD_BENEFITS_ALT,
      Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS,
      Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS_ALT,
      Routes.CUSTOMER_DASHBOARD_PROMOTIONS,
      Routes.CUSTOMER_DASHBOARD_PROMOTIONS_ALT,
      Routes.CUSTOMER_DASHBOARD_WALLET,
      Routes.CUSTOMER_DASHBOARD_WALLET_ALT,
    ] as string[];
    const isRedirectRoute = !routesToNotRedirect.includes(location.pathname);
    if (isRedirectRoute && user && !subdomain) {
      if (userIsRetailer(user)
        && (!user.otpVerified || !user.email || !user.businessName)
      ) {
        navigate(Routes.MY_PROFILE);
        return;
      } else if (userIsFarmer(user)
        && (!user.otpVerified || !user.firstName || !user.lastName || !user.state || !user.countyId)
      ) {
        navigate(Routes.MY_PROFILE);
        return;
      }
    }
    logEvent(OtherEventType.PageView, { page_location: location.pathname });
  }, [location, user]);

  useEffect(() => (
    window.scrollTo(0, 0)
  ), [location.pathname]);

  for (const route of PublicRoutes) {
    const match = matchPath(
      route,
      location.pathname,
    );
    if (match) {
      return (<PublicView />);
    }
  }

  if (sessionState === SessionState.LOGGED_OUT) {
    if (subdomain) {
      return <StorefrontLoginView />;
    } else {
      return (AppConfig.env.prod || AppConfig.env.staging)
        ? <UnderConstructionView />
        : <LoginView />;
    }
  } else if (SessionState.LOGGED_IN && user) {
    // check UserType directly on user object and not the internal and isRetailer flag
    // since those states might not have been updated yet
    if (userIsInternal(user)) {
      return <InternalView />;
    } else if (userIsRetailer(user)) {
      return AppConfig.env.test ? <RetailerView /> : <UnderConstructionView />;
    }
    return (AppConfig.env.test || subdomain) ? <LoggedInView /> : <UnderConstructionView />;
  } else {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const getManifest = (subdomain: string | null | undefined) => {
  const name = subdomain ? 'Storefront' : 'GROWERS';
  const prefix = subdomain ? `${subdomain}/` : '';
  const manifest = {
    background_color: '#ffffff',
    display: 'standalone',
    icons: [
      {
        density: '0.75',
        sizes: '36x36',
        src: `${prefix}android-icon-36x36.png`,
        type: 'image/png',
      },
      {
        density: '1.0',
        sizes: '48x48',
        src: `${prefix}android-icon-48x48.png`,
        type: 'image/png',
      },
      {
        density: '1.5',
        sizes: '72x72',
        src: `${prefix}android-icon-72x72.png`,
        type: 'image/png',
      },
      {
        density: '2.0',
        sizes: '96x96',
        src: `${prefix}android-icon-96x96.png`,
        type: 'image/png',
      },
      {
        density: '3.0',
        sizes: '144x144',
        src: `${prefix}android-icon-144x144.png`,
        type: 'image/png',
      },
      {
        density: '4.0',
        sizes: '192x192',
        src: `${prefix}android-icon-192x192.png`,
        type: 'image/png',
      },
    ],
    name: name,
    short_name: name,
    start_url: '.',
    theme_color: '#000000',
  };
  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  return URL.createObjectURL(blob);
};

const App = () => {
  const subdomain = getSubdomain();
  const pageTitle = subdomain ? 'Storefront' : 'GROWERS';
  const prefix = subdomain ? `${subdomain}/` : '';
  return (
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={getTheme()}>
          <CssBaseline />
          <GlobalSnackbarProvider>
            <ShoppingCartProvider>
              <AuthenticationProvider>
                <Helmet>
                  <title>{pageTitle}</title>
                  <style>{'body { background-color: #181818; }'}</style>
                  <meta content={pageTitle} name="description"/>
                  <link
                    href={`${prefix}apple-icon-57x57.png`}
                    rel="apple-touch-icon"
                    sizes="57x57"
                  />
                  <link
                    href={`${prefix}apple-icon-60x60.png`}
                    rel="apple-touch-icon"
                    sizes="60x60"
                  />
                  <link
                    href={`${prefix}apple-icon-72x72.png`}
                    rel="apple-touch-icon"
                    sizes="72x72"
                  />
                  <link
                    href={`${prefix}apple-icon-76x76.png`}
                    rel="apple-touch-icon"
                    sizes="76x76"
                  />
                  <link
                    href={`${prefix}apple-icon-114x114.png`}
                    rel="apple-touch-icon"
                    sizes="114x114"
                  />
                  <link
                    href={`${prefix}apple-icon-120x120.png`}
                    rel="apple-touch-icon"
                    sizes="120x120"
                  />
                  <link
                    href={`${prefix}apple-icon-144x144.png`}
                    rel="apple-touch-icon"
                    sizes="144x144"
                  />
                  <link
                    href={`${prefix}apple-icon-152x152.png`}
                    rel="apple-touch-icon"
                    sizes="152x152"
                  />
                  <link
                    href={`${prefix}apple-icon-180x180.png`}
                    rel="apple-touch-icon"
                    sizes="180x180"
                  />
                  <link
                    href={`${prefix}android-icon-192x192.png`}
                    rel="icon"
                    sizes="192x192"
                    type="image/png"
                  />
                  <link
                    href={`${prefix}favicon.ico`}
                    rel="icon"
                    sizes="32x32"
                    type="image/png"
                  />
                  <link
                    href={`${prefix}favicon-96x96.png`}
                    rel="icon"
                    sizes="96x96"
                    type="image/x-icon"
                  />
                  <link
                    href={`${prefix}favicon.ico`}
                    rel="icon"
                    sizes="16x16"
                    type="image/x-icon"
                  />
                  {/*
                   manifest provides metadata used when your web app is installed on a
                   user's mobile device or desktop.
                   See https://developers.google.com/web/fundamentals/web-app-manifest/
                   */}
                  <link href={getManifest(subdomain)} rel="manifest"/>
                </Helmet>
                <MainView/>
              </AuthenticationProvider>
            </ShoppingCartProvider>
          </GlobalSnackbarProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </HashRouter>
  );
};

export default App;
