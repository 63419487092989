import {
  Button,
  Card,
  Dialog,
  HSpacer,
  IconButton,
  Text,
  TopAppBar,
  VSpacer,
} from '@/components/DesignSystem';
import { Routes } from '@/constants/Routes';
import { CircularProgress, Container, Divider, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  CurrencyExchangeOutlined,
  ShoppingCartOutlined,
  StarOutline,
  ThumbUpOutlined,
} from '@mui/icons-material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useDashboard } from '@/hooks/useDashboard';
import { useDashboardNavigate } from '@/pages/CustomerDashboard/helpers';

const infoCardStyle = {
  borderRadius: '8px',
  boxShadow: '0px 6px 12px -6px rgba(24, 39, 75, 0.12),' +
    '0px 8px 24px -4px rgba(24, 39, 75, 0.08);',
  padding: '0px',
  position: 'relative',
};

const infoCardContentStyle = {
  p: '16px',
  '&:last-child': {
    pb: '16px',
  },
};

export const HowItWorksPage = () => {
  const theme = useTheme();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigateToPage = useDashboardNavigate();
  const [showLoyaltyInfoModal, setShowLoyaltyInfoModal] = useState(false);

  const { data: dashboardData } = useDashboard(accessToken);

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems="center">
          <VSpacer size="14" />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const { retailer } = dashboardData;

  const Header = (
    <TopAppBar
      isStatic
      leftAccessory={(
        <IconButton
          onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD, accessToken)}
          sx={{ color: 'white' }}
          testID="back-button"
        >
          <ArrowBack />
        </IconButton>
      )}
      rightAccessory={<HSpacer size="7" />}
      sx={{ bgcolor: retailer.brandColor }}
      testID="top-app-bar"
    >
      <Text category="title-large" sx={{ color: 'white' }}>
        How it Works
      </Text>
    </TopAppBar>
  );

  const LoyaltyInfoModal = (
    <Dialog
      cancelButton={(props) => (
        <Button
          onClick={() => setShowLoyaltyInfoModal(false)}
          sx={{
            border: `1px solid ${retailer.brandColor}`,
            color: retailer.brandColor,
            p: '10px 16px',
          }}
          variant="outlined"
          {...props}
        >
          Close
        </Button>
      )}
      onClose={() => setShowLoyaltyInfoModal(false)}
      open
      showCloseButton={false}
      testID="loyalty-info-modal"
    >
      <Stack alignItems="center">
        <InfoOutlined sx={{ color: retailer.brandColor }} />
        <VSpacer size="5" />
        <VSpacer size="1" />
        <Text category="headline-small">
          Loyalty Points
        </Text>
        <VSpacer size="5" />
        <Text category="title-small">
          1 point = $1 reward
        </Text>
        <VSpacer size="5" />
        <Stack color="#525252">
          <Text category="body-medium">
            Loyalty points are a currency offered by {retailer.name} within our loyalty program.
            These points have a monetary value only through orders placed with {retailer.name}.
            The rate at which a customer earns points is defined by the rewards program points
            multipliers which differ between our loyalty tiers.
          </Text>
          <VSpacer size="5" />
          <Text category="body-xsmall">
            Loyalty points are not earned on miscellaneous fees, taxes, surcharges, etc.
          </Text>
        </Stack>
      </Stack>
    </Dialog>
  );

  return  (
    <>
      <Stack
        bgcolor={theme.palette.grey[200]}
        maxWidth="599px"
        minHeight="100vh"
        minWidth="360px"
      >
        {Header}
        <Stack alignItems="center" p="20px 16px">
          <Text align="center" category="body-large">
            You’ll be automatically enrolled in the {retailer.name}
            {' '}
            Loyalty Program simply by placing an order!
          </Text>
        </Stack>
        <Divider />
        <Stack gap="16px" p="16px">
          <InfoCard
            brandColor={retailer.brandColor}
            description="Contact your seller directly to place an order or visit our website."
            icon={<ShoppingCartOutlined sx={{ height: '32px', width: '32px' }} />}
            title="Shop your way"
          />
          <InfoCard
            brandColor={retailer.brandColor}
            description={'Collect loyalty points on all products purchased.' +
             ' Special product promotions can help you earn even more points!'}
            icon={<StarOutline sx={{ height: '32px', width: '32px' }} />}
            onClick={() => setShowLoyaltyInfoModal(true)}
            title="Earn loyalty points"
            titleAccessory={(
              <InfoOutlined sx={{ color: retailer.brandColor }}/>
            )}
          />
          <InfoCard
            brandColor={retailer.brandColor}
            description={(
              <Text category="body-medium" sx={{ color: '#525252' }}>
                Apply eligible points as a discount on your next purchase.
                <span style={{ fontWeight: 500 }}> 1 point = $1</span>
              </Text>
            )}
            icon={<CurrencyExchangeOutlined sx={{ height: '32px', width: '32px' }} />}
            title="Redeem and save"
          />
          <InfoCard
            brandColor={retailer.brandColor}
            description="Shop with us and reach goals that earn you a new rewards tier
            with additional benefits and higher loyalty points percentages."
            icon={<ThumbUpOutlined sx={{ height: '32px', width: '32px' }} />}
            title="Loyal customers earn more"
          />
        </Stack>
      </Stack>
      {showLoyaltyInfoModal && LoyaltyInfoModal}
    </>
  );
};

interface InfoCardProps {
  brandColor: string,
  description: React.ReactNode,
  icon: React.ReactNode,
  onClick?: () => void,
  title: string,
  titleAccessory?: React.ReactNode,
}

const InfoCard = ({
  brandColor,
  description,
  icon,
  onClick,
  title,
  titleAccessory,
}: InfoCardProps) => (
  <Card
    cardContentStyle={infoCardContentStyle}
    onClick={onClick}
    sx={[infoCardStyle, { cursor: onClick ? 'pointer' : 'default' }]}
    testID={`info-card-${title}`}
  >
    <Stack alignItems="center" direction="row">
      <Text category="title-large">
        {title}
      </Text>
      {titleAccessory && (
        <>
          <HSpacer size="3" />
            {titleAccessory}
        </>
      )}
    </Stack>
    <VSpacer size="3" />
    <Stack direction="row" justifyContent="space-between">
      {typeof description === 'string' ? (
        <Text category="body-medium" sx={{ color: '#525252' }}>
          {description}
        </Text>
      ) : description}
      <HSpacer size="7" />
      <Stack
        alignItems="center"
        bgcolor={brandColor}
        borderRadius="4px"
        color="white"
        justifyContent="center"
        maxHeight="48px"
        maxWidth="48px"
        minHeight="48px"
        minWidth="48px"
      >
        {icon}
      </Stack>
    </Stack>
  </Card>
);
