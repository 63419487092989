import { CircularProgress, Stack } from '@mui/material';
import { StackProps } from '@mui/system';
import { debounce } from 'lodash';
import { ReactNode, useEffect, useRef } from "react";

interface InfiniteScrollProps extends StackProps {
  children: ReactNode,
  fetchMoreItems: () => Promise<any>,
  hasMore: boolean,
  isLoading: boolean,
}

export const InfiniteScroll = ({
  children,
  fetchMoreItems,
  hasMore,
  isLoading,
  ...remaining
}: InfiniteScrollProps) => {
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    void loadMoreItems();
  }, []);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!containerRef.current) {
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= (scrollHeight / 2) && hasMore && !isLoading) {
        void loadMoreItems();
      }
    }, 200);

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore, isLoading]);

  const loadMoreItems = async () => {
    if (isLoading) {
      return;
    }
    await fetchMoreItems();
  };

  return (
    <Stack height="100vh" overflow="auto" ref={containerRef} {...remaining}>
      {children}
      {isLoading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </Stack>
  );
};
