import { Chip, HSpacer, Text, TextLink } from "@/components/DesignSystem";
import { FilterChip } from "@/components/DesignSystem/Toolbar/FilterChip";
import { FilterMenu } from "@/components/DesignSystem/Toolbar/FilterMenu";
import { FilterSheet } from "@/components/DesignSystem/Toolbar/FilterSheet";
import {
  Filter,
  FilterSelection,
  FilterSelections,
  NonBooleanFilter,
} from "@/components/DesignSystem/Toolbar/interfaces";
import { getResponsiveValue, useMediaQuery } from "@/hooks/useMediaQuery";
import Tune from "@mui/icons-material/Tune";
import { Stack } from "@mui/material";
import _ from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";

interface FilterBarProps {
  filters: Filter[],
  hideAllFilter?: boolean
  onChange?: (selections: FilterSelections) => void,
  selections: FilterSelections,
  showClearAllButton?: boolean,
  testID: string,
  themeColor?: string,
}

export const FilterBar = ({
  filters = [],
  hideAllFilter = false,
  onChange,
  selections,
  showClearAllButton = false,
  testID,
  themeColor,
}: FilterBarProps) => {
  const [showFilterSheet, setShowFilterSheet] = useState(false);
  const [selectedFilterId, setSelectedFilterId] = useState<string | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const { isMobile } = useMediaQuery();

  const selectedFilter = useMemo(() => (
    selectedFilterId
      ? filters.find((filter) => (
        filter.id === selectedFilterId && filter.selectionMethod !== 'boolean'
      ))
      : undefined
  ), [filters, selectedFilterId]);

  const handleSelectionChange = useCallback((filterId: string, selection: FilterSelection) => {
    const newSelections = _.cloneDeep(selections);
    newSelections.set(filterId, selection);
    onChange?.(newSelections);
  }, [onChange, selections]);

  const hasSelectedFilters = () => {
    if (selections.size > 0) {
      for (const selection of selections.values()) {
        if (selection.size > 0) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <Stack
        alignItems="center"
        className="scrollable-container"
        direction="row"
        flexWrap={getResponsiveValue('nowrap', 'wrap')}
        rowGap="8px"
      >
        {!hideAllFilter &&
          <Stack direction="row">
            <Chip
              icon={<Tune color="primary" />}
              label={
                !isMobile ? (
                  <Text category="label-large">
                    All Filters
                  </Text>
                ) : undefined
              }
              onClick={() => {
                setSelectedFilterId(null);
                setShowFilterSheet(true);
              }}
              size="medium"
              testID={`${testID}-chip-all-filters`}
              variant="outlined"
            />
            <HSpacer size="3" />
          </Stack>
        }
        {filters.map((filter) => (
          <Fragment key={filter.id}>
            <FilterChip
              disabled={!filter.options.length}
              filter={filter}
              onClick={(event) => {
                if (filter.selectionMethod === 'boolean') {
                  handleSelectionChange(
                    filter.id,
                    selections.get(filter.id)?.has(filter.id)
                      ? new Set()
                      : new Set([filter.options[0].id]),
                  );
                } else if (!isMobile) {
                  setSelectedFilterId(filter.id);
                  setMenuAnchorEl(event.currentTarget);
                } else {
                  setSelectedFilterId(filter.id);
                  setShowFilterSheet(true);
                }
              }}
              selection={selections.get(filter.id)}
              testID={`${testID}-chip-${filter.id}`}
            />
            <HSpacer size="3" />
          </Fragment>
        ))}
        {showClearAllButton && hasSelectedFilters() && (
          <>
            <HSpacer size="4" />
            <TextLink
              category="title-medium"
              onClick={() => onChange?.(new Map())}
              testID={`${testID}-clear-all-button`}
            >
              Clear
            </TextLink>
          </>
        )}
      </Stack>
      {(!isMobile && selectedFilter) && (
        <FilterMenu
          anchorEl={menuAnchorEl}
          filter={selectedFilter as NonBooleanFilter}
          onChange={(selection) => {
            if (selectedFilter.selectionMethod === 'single-select') {
              setSelectedFilterId(null);
            }
            handleSelectionChange(selectedFilter.id, selection);
          }}
          onClose={() => setSelectedFilterId(null)}
          open
          selection={selections.get(selectedFilter.id)}
          testID={`${testID}-menu`}
        />
      )}
      <FilterSheet
        filters={selectedFilter ? [selectedFilter] : filters}
        onApply={onChange}
        onClose={() => setShowFilterSheet(false)}
        open={showFilterSheet}
        selections={selections}
        testID={`${testID}-sheet`}
        themeColor={themeColor}
        title={selectedFilter ? selectedFilter.label : "Filters"}
      />
    </>
  );
};

