import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { palette as landusPalette } from '../Landus/palette';
import { components } from './components';
import { DarkPalette, LightPalette } from './palette';
import { defaultTypography } from './typography';

const baseTheme = createTheme({ palette: DarkPalette });
const baseLightTheme = createTheme({ palette: LightPalette });
const muiDarkTheme = createTheme({
  components: components(baseTheme),
  palette: DarkPalette,
  typography: defaultTypography,
});

const muiLightTheme = createTheme({
  components: components(baseLightTheme),
  palette: LightPalette,
  typography: defaultTypography,
});

const landusTheme = createTheme({
  components: components(baseTheme),
  palette: landusPalette,
  typography: defaultTypography,
});

export const themes: { [domain: string]: Theme } = {
  marketplace: muiDarkTheme,
  marketplaceLight: muiLightTheme,
  landus: landusTheme,
};
