import { toUpper } from 'lodash';

export * from './AccountGroupCustomerRole';
export * from './ChatIdPrefix';
export * from './DeliverableTemplateType';
export * from './Errors';
export * from './ErrorType';
export * from './FeatureFlag';
export * from './FulfillmentMethod';
export * from './GrowersContactInfo';
export * from './ImportStatus';
export * from './ImportType';
export * from './LedgerType';
export * from './NotificationType';
export * from './NotificationType';
export * from './OfferStatus';
export * from './OrderSource';
export * from './PackageType';
export * from './PaymentType';
export * from './PrepaymentType';
export * from './PriceOfferStatusFilter';
export * from './PricingRequestApproval';
export * from './PricingRequestStatus';
export * from './ProductEventType';
export * from './PushNotificationRedirect';
export * from './PushNotificationType';
export * from './RecommendationFarmerStatus';
export * from './RewardsType';
export * from './SponsorType';
export * from './State';
export * from './TransactionType';
export * from './UnitOfMeasures';
export * from './UserType';
// putting this down at the bottom due to some possible circular dependency issue
export * from './RewardsUom';

export function parseEnum (dataEnum: object, input: string | number | null | undefined) {
  if (!input) {
    return undefined;
  }

  const match = Object.values(dataEnum).find(
    (value) => (typeof value === 'string' ? toUpper(value) : value)
      === (typeof input === 'string' ? toUpper(input) : input),
  );
  return (match || undefined);
}

export function parseEnums (dataEnums: object[], input: string | number | null | undefined) {
  for (const dataEnum of dataEnums) {
    const parsedValue = parseEnum(dataEnum, input);
    if (parsedValue) { return parsedValue; }
  }

  return undefined;
}
/**
 * Throw error if value received does not match a known enum
 * @param dataEnums
 * @param errMessage
 */
export function validateEnum (dataEnums: object | object[], errMessage: string) {
  return {
    fn: (value: unknown) => {
      const validValues = Array.isArray(dataEnums)
        ? dataEnums.map((dataEnum) => Object.values(dataEnum)).flat()
        : Object.values(dataEnums);
      if (!validValues.includes(value)) {
        throw new Error(`${errMessage}: ${value}`);
      }
    },
  };
}
