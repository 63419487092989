import {
  Button,
  Checkbox,
  Chip,
  HSpacer,
  IconButton,
  MenuItem,
  Search,
  Text,
} from "@/components/DesignSystem";
import {
  Filter,
  FilterOption,
  FilterSelection,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { ButtonGroup, ListItemText, Stack } from '@mui/material';
import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";

interface RawFilterSelectorProps {
  filter: Filter,
  onChange?: (selection: FilterSelection) => void,
  onShowMoreClicked?: () => void,
  selection?: FilterSelection,
  testID: string,
  themeColor?: string,
}

const RawFilterSelector = ({
  filter,
  onChange,
  onShowMoreClicked,
  selection = new Set(),
  testID,
  themeColor,
}: RawFilterSelectorProps) => {
  const { isMobile } = useMediaQuery();
  const [search, setSearch] = useState('');
  const [subOptionVisibility, setSubOptionVisibility] = (
    useState<{ [optionId: string]: boolean }>({})
  );

  const options = onShowMoreClicked ? filter.options.slice(0, 6) : filter.options;

  useEffect(() => {
    const newSubOptionVisibility: { [optionId: string]: boolean } = {};
    options.forEach((option) => {
      if (option.subOptions?.length && subOptionVisibility[option.id] === undefined) {
        newSubOptionVisibility[option.id] = false;
      }
    });
    if (Object.keys(newSubOptionVisibility).length) {
      setSubOptionVisibility({
        ...subOptionVisibility,
        ...newSubOptionVisibility,
      });
    }
  }, [filter]);

  const doesOptionMatchSearch = (option: FilterOption) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  };

  const getNumberOfOptions = () => {
    return options.reduce((acc, option) => (
      acc + 1 + (option.subOptions?.length ?? 0)
    ), 0);
  };

  const getSubOptionsMatchingSearch = (option: FilterOption) => {
    if (!option.subOptions) {
      return [];
    }
    const parentOptionMatchesSearch = doesOptionMatchSearch(option);
    if (parentOptionMatchesSearch) {
      return option.subOptions;
    }
    return option.subOptions.filter(
      (option) => doesOptionMatchSearch(option),
    );
  };

  const handleChange = useCallback((newSelection: FilterSelection) => {
    onChange?.(newSelection);
  }, [onChange]);

  const handleParentOptionSelect = (option: FilterOption) => {
    const newSelection = new Set(selection);
    const isParent = !!option.subOptions?.length;
    if (isParent) {
      const hasSomeChecked = hasSomeButNotAllSubOptionsChecked(option);
      const isChecked = isOptionChecked(option);
      if (isChecked || hasSomeChecked) {
        option.subOptions?.forEach((option) => {
          newSelection.delete(option.id);
        });
      } else {
        option.subOptions?.forEach((option) => {
          newSelection.add(option.id);
        });
      }
    } else {
      if (selection.has(option.id)) {
        newSelection.delete(option.id);
      } else {
        newSelection.add(option.id);
      }
    }
    handleChange(newSelection);
  };

  const hasSomeButNotAllSubOptionsChecked = (option: FilterOption) => {
    const isParent = !!option.subOptions?.length;
    if (isParent) {
      const areSomeSelected = option.subOptions?.some((option) => selection?.has(option.id));
      const areAllSelected = option.subOptions?.every((option) => selection?.has(option.id));
      return (areSomeSelected && !areAllSelected) ?? false;
    }
    return false;
  };

  const isOptionChecked = (option: FilterOption) => {
    const isParent = !!option.subOptions?.length;
    if (isParent) {
      const areAllSelected = option.subOptions?.every((option) => selection?.has(option.id));
      return areAllSelected ?? false;
    } else {
      return selection?.has(option.id) ?? false;
    }
  };

  switch (filter.selectionMethod) {
    case 'boolean':
    case 'chips':
      return (
        <Stack direction="row" flexWrap="wrap" pr="24px" rowGap="16px">
          {options.map((option) => (
            <Fragment key={option.id}>
              <Chip
                icon={selection.has(option.id) ? <Check color="primary" /> : undefined}
                label={
                  <Text category="label-large">
                    {option.label}
                  </Text>
                }
                onClick={() => {
                  const newSelection = new Set(selection);
                  if (newSelection.has(option.id)) {
                    newSelection.delete(option.id);
                  } else {
                    newSelection.add(option.id);
                  }
                  handleChange(newSelection);
                }}
                testID={`${testID}-${option.id}`}
                variant={selection.has(option.id) ? 'filled' : 'outlined'}
              />
              <HSpacer size="3"/>
            </Fragment>
          ))}
        </Stack>
      );
    case 'single-select':
      return <Stack>
        {options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleChange(new Set(isOptionChecked(option)
              ? []
              : [option.id],
            ))}
            {...(isMobile && isOptionChecked(option)) && { style: {
              backgroundColor: "#f6f6f6" },
            }}
            testID={`${testID}-${option.id}`}
            value={option.id}
          >
            <ListItemText>{option.label}</ListItemText>
            {isOptionChecked(option) && (
              <Check sx={{ color: themeColor || 'primary' }} />
            )}
          </MenuItem>
        ))}
        {!!onShowMoreClicked && (
          <Button
            onClick={onShowMoreClicked}
            startIcon={<AddIcon />}
            sx={{ color: themeColor, marginLeft: "15px", width: "87px" }}
            testID={`show-more-${filter.id}`}
            variant="text"
          >
            More
          </Button>
        )}
      </Stack>;
    case 'multi-select':
      return (
        <Stack>
          {(!isMobile && getNumberOfOptions() > 20) && (
            <Search
              onChangeText={(text) => {
                setSearch(text);
                // forces a rerender so menu adjusts with anchor properly
                onChange?.(new Set(selection));
              }}
              onKeyDown={(e) => e.stopPropagation()}
              testID={`${testID}-search`}
              value={search}
            />
          )}
          {options
            .filter((option) => {
              if (doesOptionMatchSearch(option)) {
                return true;
              }
              return getSubOptionsMatchingSearch(option).length > 0;
            })
            .map((option) => (
              <Fragment key={option.id}>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <MenuItem
                    onClick={() => handleParentOptionSelect(option)}
                    sx={{ padding: "8px 16px", width: "100%" }}
                    testID={`${testID}-${option.id}`}
                    value={option.id}
                  >
                    <Checkbox
                      checked={isOptionChecked(option)}
                      indeterminate={hasSomeButNotAllSubOptionsChecked(option)}
                      testID={`${testID}-${option.id}`}
                      {...(themeColor && {
                        sx: {
                          color: themeColor,
                          "&.Mui-checked": {
                            color: themeColor,
                          },
                        },
                      })}
                    >
                      {option.label}
                      {!!option.subline && (
                        <Text category="body-medium">{option.subline}</Text>
                      )}
                    </Checkbox>
                  </MenuItem>
                  {!!(getSubOptionsMatchingSearch(option).length) &&
                    <IconButton
                      onClick={() => {
                        const currentValue = subOptionVisibility[option.id];
                        setSubOptionVisibility({
                          ...subOptionVisibility,
                          [option.id]: !currentValue,
                        });
                        // forces a rerender so menu adjusts with anchor properly
                        onChange?.(new Set(selection));
                      }}
                      testID={`${option.id}-expand-button`}
                    >
                      {subOptionVisibility[option.id] || !!search
                        ? <ExpandLess /> : <ExpandMore />
                      }
                    </IconButton>
                  }
                </Stack>
                {(subOptionVisibility[option.id] || !!search) && !!option.subOptionLabel
                  && <Text category="title-small">{option.subOptionLabel}</Text>
                }
                {(subOptionVisibility[option.id] || !!search)
                  && getSubOptionsMatchingSearch(option).map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={() => {
                        const newSelection = new Set(selection);
                        if (selection.has(option.id)) {
                          newSelection.delete(option.id);
                        } else {
                          newSelection.add(option.id);
                        }
                        handleChange(newSelection);
                      }}
                      sx={{ paddingLeft: "36px", width: "100%" }}
                      testID={`${testID}-${option.id}`}
                      value={option.id}
                    >
                      <Checkbox
                        checked={selection.has(option.id)}
                        testID={`${testID}-${option.id}`}
                      >
                        {option.label}
                      </Checkbox>
                    </MenuItem>
                  ))
                }
              </Fragment>
            ))}
          {!!onShowMoreClicked && (
            <Button
              onClick={onShowMoreClicked}
              startIcon={<AddIcon />}
              sx={{ color: themeColor, marginLeft: "15px", width: "87px" }}
              testID={`show-more-${filter.id}`}
              variant="text"
            >
              More
            </Button>
          )}
        </Stack>
      );
    case 'segmented-button':
      return (
        <ButtonGroup>
          {options.map((option) => (
            <Button
              aria-selected={selection.has(option.id)}
              key={option.id}
              onClick={() => handleChange(new Set([option.id]))}
              testID={`${testID}-${option.id}`}
            >
              {selection.has(option.id) && (
                <>
                  <Check/>
                  <HSpacer size="2"/>
                </>
              )}
              {option.label}
            </Button>
          ))}
        </ButtonGroup>
      );
  }
};

export const FilterSelector = memo(RawFilterSelector);