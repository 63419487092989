import React, { Fragment } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import { Text } from "@/components/DesignSystem";
import Check from '@mui/icons-material/Check';

interface TierProgressBarProps {
  currentRank: number | null,
  tierNames: string[],
}

export const TierProgressBar = ({
  currentRank,
  tierNames,
}: TierProgressBarProps) => {
  const theme = useTheme();
  const isTierEarned = (tierIndex: number) => currentRank !== null && tierIndex <= currentRank;

  const progressRatio = currentRank === null
    ? 0
    : Math.min((currentRank + .5) / (tierNames.length - 1), 1);
  const isComplete = progressRatio === 1;
  const ProgressSegment = (
    <Box
      bgcolor="#237804"
      height="100%"
      position="absolute"
      width={`${progressRatio * 100}%`}
    />
  );

  const ProgressDot = (
    <Box
      bgcolor={isComplete ? '#135200' : '#389E0D'}
      border="2px solid white"
      borderRadius="50%"
      height="20px"
      left={`${progressRatio * 100}%`}
      position="absolute"
      sx={{ transform: 'translate(-50%, -30%)' }}
      width="20px"
    >
      {isComplete && (
        <Check
          sx={{
            color: 'white',
            height: '15px',
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '15px',
          }}
        />
      )}
    </Box>
  );

  return (
    <Box height="26px" maxWidth="300px" width="100%">
      <Box bgcolor="#E0E0E0" height="8px" position="relative">
        {ProgressSegment}
        {tierNames.map((name, index) => (
          <Fragment key={name}>
            <Dot
              isEarned={isTierEarned(index)}
              left={`${index * (100 / (tierNames.length - 1))}%`}
            />
            <Text
              fontSize="10px"
              fontWeight={500}
              left={`${index * (100 / (tierNames.length - 1))}%`}
              position="absolute"
              sx={{ color: theme.palette.grey[800], transform: 'translate(-50%, 0%)' }}
              top={12}
            >
              {name.toUpperCase()}
            </Text>
          </Fragment>
        ))}
        {currentRank !== null && ProgressDot}
      </Box>
    </Box>
  );
};

const Dot = ({ isEarned, ...rest }: { isEarned: boolean } & BoxProps) => (
  <Box
    bgcolor={isEarned ? '#135200' : '#B5B5B5'}
    borderRadius="50%"
    height="8px"
    position="absolute"
    sx={{ transform: 'translate(-50%, 0%)' }}
    width="8px"
    {...rest}
  />
);
