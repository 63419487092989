import { Stack } from '@mui/material';
import { debounce } from 'lodash';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface StickyElementProps {
  children: ReactNode,
}

const StickyElement = ({
  children,
}: StickyElementProps) => {
  const [isSticky, setSticky] = useState(false);
  const stickyRef = useRef<HTMLDivElement | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const handleIntersection = debounce(([entry]: IntersectionObserverEntry[]) => {
    setSticky(!entry.isIntersecting);
  }, 100);

  useEffect(() => {
    const sentinelNode = sentinelRef.current;
    const observer = new IntersectionObserver(
      handleIntersection,
      {
        root: null, // Observe relative to the viewport
        rootMargin: '-1px 0px 0px 0px',
        threshold: 0, // Trigger as soon as the sentinel leaves the viewport
      },
    );

    if (sentinelNode) {
      observer.observe(sentinelNode);
    }

    return () => {
      if (sentinelNode) {
        observer.unobserve(sentinelNode);
      }
    };
  }, [sentinelRef, handleIntersection, stickyRef]);

  return (
    <>
      <Stack ref={sentinelRef} style={{ height: '1px', marginTop: '0px' }} />
      {isSticky && <div style={{ paddingBottom: stickyRef.current?.offsetHeight }} />}
      <Stack
        ref={stickyRef}
        sx={isSticky
          ? {
            boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 1000,
          }
          : {}
        }
      >
        {children}
      </Stack>
    </>
  );
};

export default StickyElement;
