import { Button, Modal, Text, VSpacer } from '@/components/DesignSystem';
import { ApiPromotion } from '@api/interfaces';
import { Box, Stack } from '@mui/material';
import { PromotionCard } from '@/pages/Admin/Product/PromotionCard';
import { useState } from 'react';
import { PromotionDetailsModal } from '@/pages/Admin/Promotions/PromotionDetailsModal';
import { sortBy } from 'lodash';

interface RewardsModalProps {
  onClose: () => void,
  promotions: ApiPromotion[],
}

export const RewardsModal = ({
  onClose,
  promotions,
}: RewardsModalProps) => {
  const [selectedPromotion, setSelectedPromotion] = useState<ApiPromotion | null>(null);

  const sortedPromotions = sortBy(promotions, 'endDate');

  return (
    <>
      <Modal
        acceptButton={(props) => (
          <Button {...props} onClick={onClose} sx={{ width: '84px' }} variant="contained">
            Close
          </Button>
        )}
        onClose={onClose}
        open
        overflow="hidden"
        testID="rewards-modal"
        title="Rewards"
      >
        <Text category="body-medium">
          Point values are per $1 spent or per unit of measure purchased. See promotion details.
        </Text>
        <VSpacer size="5" />
        <Stack maxHeight="50vh" minHeight="0" overflow="hidden">
          <Box overflow="auto">
            <Stack gap="16px">
              {sortedPromotions.map((promotion) => (
                <PromotionCard
                  key={promotion.id}
                  onClickDetails={() => setSelectedPromotion(promotion)}
                  promotion={promotion}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Modal>
      {!!selectedPromotion && (
        <PromotionDetailsModal
          onBack={() => setSelectedPromotion(null)}
          onClose={onClose}
          promotionId={selectedPromotion.id}
        />
      )}
    </>
  );
};