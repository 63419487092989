import { RewardsLoyaltyValueInput } from "@/components/shared/RewardsLoyaltyValueInput";
import { PromotionInputs } from "@/pages/Admin/Promotions/PromotionModal/PromotionModal";
import React from "react";

interface WorkflowStep2Props {
  onPromotionChanged: (promotion: PromotionInputs) => void,
  promotion: PromotionInputs,
}

export const WorkflowStep2 = ({
  onPromotionChanged,
  promotion,
}: WorkflowStep2Props) => {
  return <>
    <RewardsLoyaltyValueInput
      onUpdate={(updates) => {
        onPromotionChanged(
          {
            ...promotion,
            ...updates,
            ...(updates.uom !== promotion.uom && {
              productIdsForEarningPoints: undefined,
              productIdsForRedeemingPoints: undefined,
            }),
          },
        );
      }}
      rewardType={promotion.rewardType}
      testID={`${promotion.id}-loyalty-rewards-input`}
      uom={promotion.uom}
      value={promotion.value}
    />
  </>;
};
