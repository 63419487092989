import { AppConfig } from '@/constants/AppConfig';
import { PointsEarnedLineItem } from '@/pages/CustomerDashboard/Benefits/PointsEarnedLineItem';
import { ElevatedCard } from '@/pages/CustomerDashboard/ElevatedCard';
import { getRewardsValueText } from '@/pages/CustomerDashboard/helpers';
import { ApiManufacturer, ApiPromotion } from '@api/interfaces';
import { Divider } from '@mui/material';
import React from 'react';

interface ManufacturerPromotionsPointsEarnedCardProps {
  manufacturer: ApiManufacturer,
  promotions: ApiPromotion[],
  testID: string,
}

export const ManufacturerPromotionsPointsEarnedCard = ({
  manufacturer,
  promotions,
  testID,
}: ManufacturerPromotionsPointsEarnedCardProps) => {
  const imageUrl = manufacturer.logo && `${AppConfig.staticImageHost}/${manufacturer.logo}`;
  const sortedPromotions = promotions.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <ElevatedCard
      imageUrl={imageUrl}
      testID={testID}
      title={manufacturer.name ?? ''}
    >
      <Divider />
      {sortedPromotions.map((promotion) => (
        <PointsEarnedLineItem
          key={promotion.id}
          label={promotion.name}
          value={getRewardsValueText(promotion.rewardType, promotion.value, promotion.uom)}
        />
      ))}
    </ElevatedCard>
  );
};
