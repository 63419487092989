import { Card, HSpacer, Text, VSpacer } from '@/components/DesignSystem';
import { Avatar, Stack, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

interface ElevatedCardProps {
  children: ReactNode,
  imageUrl?: string | null,
  testID: string,
  title: string,
  titleAccessory?: ReactNode,
}

export const ElevatedCard = ({
  children,
  imageUrl,
  testID,
  title,
  titleAccessory,
}: ElevatedCardProps) => {
  const theme = useTheme();
  return (
    <Card
      cardContentStyle={{ p: "16px 16px 20px 16px" }}
      sx={{
        backgroundColor: theme.palette.background.default,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      }}
      testID={testID}
    >
      <Stack direction="row">
        {!!imageUrl && (
          <>
            <Avatar
              alt={`${title} logo`}
              src={imageUrl}
              sx={{
                bgcolor: theme.palette.background.default,
                height: 34,
                width: 34,
              }}
            />
            <HSpacer size="4" />
          </>
        )}
        <Stack>
          <Text category="body-xlarge" fontWeight={500}>
            {title}
          </Text>
          {titleAccessory && (
            <>
              <VSpacer size="3" />
              {titleAccessory}
            </>
          )}
        </Stack>
      </Stack>
      <VSpacer size="5"/>
      {children}
    </Card>
  );
};
