import { Button, Dialog, Text, VSpacer } from '@/components/DesignSystem';
import { buttonHoverStyle } from '@/pages/CustomerDashboard/CustomerDashboard';
import { ApiManufacturer, ApiProduct, ApiRetailer } from '@api/interfaces';
import { Stack } from '@mui/material';
import { SponsorType } from '@shared/enums';
import React from 'react';

interface RedeemableProductsModalProps {
  manufacturer?: ApiManufacturer | null,
  onClose: () => void,
  productsForRedeemingPoints: ApiProduct[],
  retailer: ApiRetailer,
  sponsorType: SponsorType,
}

export const RedeemableProductsModal = ({
  manufacturer,
  onClose,
  productsForRedeemingPoints,
  retailer,
  sponsorType,
}: RedeemableProductsModalProps) => {
  const productRedeemNames = productsForRedeemingPoints.map(
    ({ name }) => name,
  ).sort().join(', ');
  return (
    <Dialog
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
          sx={{
            borderColor: retailer.brandColor,
            color: retailer.brandColor,
            ...buttonHoverStyle,
          }}
          variant="outlined"
        >
          Close
        </Button>
      )}
      onClose={onClose}
      open
      showCloseButton={false}
      testID="redeemable-products-modal"
    >
      {!!productsForRedeemingPoints.length && (
        <Stack>
          <Text category="body-large">
            Points redeemable on select products.
          </Text>
          <VSpacer size="5" />
          <Text category="overline" textTransform="uppercase">
            Participating Products
          </Text>
          <VSpacer size="3" />
          <Text category="body-medium">
            {productRedeemNames}
          </Text>
        </Stack>
      )}
      {!productsForRedeemingPoints.length && (
        <Text category="body-large">
          {sponsorType === SponsorType.Manufacturer
            ? `Points redeemable on all ${manufacturer?.name} products.`
            : `Points redeemable on all products.`
          }
        </Text>
      )}
    </Dialog>
  );
};

