import { PaletteOptions } from '@mui/material';

export type Color = (
  'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
);

export const DarkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#EDF354',
  },
  secondary: {
    main: '#EDF354',
  },
  background: {
    default: '#181817',
  },
  error: {
    main: '#FFA198',
  },
  success: {
    main: '#8FD460',
  },
  warning: {
    main: '#FFA940',
  },
  info: {
    main: '#8DCFF8',
  },
  divider: '#3F3E38',
};

export const LightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#141414',
  },
  secondary: {
    main: '#EDF354',
  },
  background: {
    default: '#ffffff',
    paper: '#F0F0F0',
  },
  error: {
    main: '#FFA198',
  },
  success: {
    main: '#8FD460',
  },
  warning: {
    main: '#FFA940',
  },
  info: {
    main: '#8DCFF8',
  },
  divider: '#F0F0F0',
  text: {
    primary: '#141414',
    secondary: '#141414',
  },
};
