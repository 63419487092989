import { Button, HSpacer, IconButton, Text, TopAppBar, VSpacer } from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useDashboard } from '@/hooks/useDashboard';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { ElevatedCard } from '@/pages/CustomerDashboard/ElevatedCard';
import { DashboardWallet, Salesperson } from '@/pages/CustomerDashboard/interfaces';
import { PointsTag } from '@/components/shared/PointsTag';
import { RedeemableProductsModal } from '@/pages/CustomerDashboard/RedeemableProductsModal';
import { DashboardApi } from '@/utilities/api/DashboardApi';
import { ChatBubbleOutline } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  ButtonBase,
  CircularProgress,
  Container,
  darken,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import { SponsorType } from '@shared/enums';
import { formatPhoneNumber, groupBy } from '@shared/utilities';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDashboardNavigate } from './helpers';

export const WalletPage = () => {
  const theme = useTheme();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken') ?? params.accessToken;
  const navigateToPage = useDashboardNavigate();
  const [showSellerModal, setShowSellerModal] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState<DashboardWallet>();

  const { data: dashboardData } = useDashboard(accessToken);

  const { data: salespersons, isFetching: isSalespersonsFetching } = useQuery<Salesperson[]>(
    [QueryKeys.GET_DASHBOARD_SALESPERSONS, accessToken],
    async () => await DashboardApi.listRelevantSalespersons(accessToken!) as Salesperson[],
    {
      enabled: !!accessToken,
    },
  );

  if (!dashboardData) {
    return (
      <Container>
        <Stack alignItems='center'>
          <VSpacer size='14' />
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  const {
    orders,
    retailer,
    wallets,
  } = dashboardData;

  const sortWalletGroupByManufacturerName = (
    a: string,
    b: string,
    wallets: { [manufacturerId: string] : DashboardWallet[] },
  ): number => {
    return wallets[a][0].manufacturer!.name!.localeCompare(wallets[b][0].manufacturer!.name!);
  };

  const sortWallets = (
    a: DashboardWallet,
    b: DashboardWallet,
  ): number => {
    if (!a.promotionId && b.promotionId) {
      return -1;
    }
    if (!b.promotionId && a.promotionId) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  };

  const getWallets = () => {
    const groupedWallets: DashboardWallet[][] = [];
    const retailerWallets = wallets.filter((wallet) => (
      wallet.retailerId && !wallet.manufacturerId
    ));
    const groupedManufacturerWallets = groupBy(
      wallets.filter((wallet) => !!wallet.manufacturerId),
      (wallet) => wallet.manufacturerId!,
    );
    if (retailerWallets.length && retailerWallets.some(({ balance }) => balance > 0)) {
      groupedWallets.push(retailerWallets.sort(sortWallets));
    }
    const sortedManufacturerIdKeys = Object.keys(groupedManufacturerWallets).sort(
      (a, b) => sortWalletGroupByManufacturerName(a, b, groupedManufacturerWallets),
    );
    sortedManufacturerIdKeys.forEach((id) => {
      groupedWallets.push(groupedManufacturerWallets[id].sort(sortWallets));
    });
    return groupedWallets;
  };
  const cardWallets = getWallets();

  const getLogoSrc = (wallet: DashboardWallet) => {
    if (wallet.manufacturer) {
      return wallet.manufacturer.logo
        && `${AppConfig.staticImageHost}/${wallet.manufacturer.logo}`;
    }
    return retailer.image && `${AppConfig.staticImageHost}/${retailer.image}`;
  };

  const lastPointsProcessedDate = orders.map((order) => (
    order.importHistory.pointsProcessedDate
  )).filter(Boolean).sort().pop();
  const formattedUpdateDate = lastPointsProcessedDate
    ? lastPointsProcessedDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    : undefined;

  const Header = (
    <TopAppBar
      isStatic
      leftAccessory={(
        <IconButton
          onClick={() => navigateToPage(Routes.CUSTOMER_DASHBOARD, accessToken)}
          sx={{ color: 'white' }}
          testID="back-button"
        >
          <ArrowBack />
        </IconButton>
      )}
      rightAccessory={<HSpacer size="7" />}
      sx={{ bgcolor: retailer.brandColor }}
      testID="top-app-bar"
    >
      <Text category="title-large" sx={{ color: 'white' }}>
        Wallet
      </Text>
    </TopAppBar>
  );

  const WalletCards = (
    <Stack px="16px">
      {cardWallets.map((wallets) => (
        <Fragment key={wallets[0].name}>
          <ElevatedCard
            imageUrl={getLogoSrc(wallets[0])}
            testID={`${wallets[0].name}-card`}
            title={wallets[0].manufacturer?.name ?? retailer.name}
            titleAccessory={
              <PointsTag
                points={wallets.reduce((acc, wallet) => acc + wallet.balance, 0)}
                variant="filled"
              />
            }
          >
            {wallets.map((wallet, index) => (
              <Fragment key={wallet.id}>
                <ButtonBase
                  onClick={() => setSelectedWallet(wallet)}
                  sx={{ width: '100%' }}
                >
                  <Stack
                    border="1px solid #D9D9D9"
                    borderRadius="4px"
                    direction="row"
                    justifyContent="space-between"
                    p="16px"
                    width="100%"
                  >
                    <Text breakWord category="body-medium" textAlign="left">
                      {wallet.promotionId ? wallet.name : 'All products'}
                    </Text>
                    <HSpacer size="4" />
                    <PointsTag points={wallet.balance} starHeight="15px" starWidth="15px" />
                  </Stack>
                </ButtonBase>
                {index !== wallets.length - 1 && (
                  <VSpacer size="4" />
                )}
              </Fragment>
            ))}
          </ElevatedCard>
          <VSpacer size="6" />
        </Fragment>
      ))}
    </Stack>
  );

  return (
    <>
      <Stack
        bgcolor={theme.palette.background.default}
        flexGrow={1}
        height="auto"
        maxWidth="599px"
        minHeight="100vh"
        minWidth="360px"
      >
        {Header}
        <Stack bgcolor={darken(retailer.brandColor, 0.2)} p="16px 16px 20px">
          <Text fontSize="30px" fontWeight={500} lineHeight="36px" sx={{ color: 'white' }}>
            Place an order.<br />Redeem rewards.
          </Text>
          <VSpacer size="5" />
          <Stack direction="row" justifyContent="space-between">
            <Stack
              bgcolor={theme.palette.background.default}
              flex={1}
              justifyContent="space-between"
              sx={{ borderRadius: '4px', p: '8px 12px 12px' }}
            >
              <Text category="body-medium">
                Message your seller directly
              </Text>
              <VSpacer size="4" />
              <Stack alignItems="center">
                <Button
                  color="inherit"
                  disabled={isSalespersonsFetching}
                  onClick={() => setShowSellerModal(true)}
                  startIcon={<ChatBubbleOutline />}
                  sx={{ borderColor: theme.palette.grey[300], color: theme.palette.text.primary }}
                  testID="message-seller-button"
                  variant="outlined"
                >
                  Message
                </Button>
              </Stack>
            </Stack>
            <HSpacer size="5" />
            <Stack
              bgcolor={theme.palette.background.default}
              flex={1}
              sx={{ borderRadius: '4px', p: '8px 12px 12px' }}
            >
              <Text category="body-medium">
                Visit the {retailer.name} website
              </Text>
              <VSpacer size="4" />
              <Stack alignItems="center">
                <Button
                  color="inherit"
                  disabled={!retailer.websiteUrl}
                  endIcon={<ArrowForward />}
                  onClick={() => window.open(retailer.websiteUrl!, '_blank')}
                  sx={{ borderColor: theme.palette.grey[300] }}
                  testID="visit-retailer-website-button"
                  variant="outlined"
                >
                  Go
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack bgcolor="#f7f7f7" flexGrow={1}>
          <VSpacer size="4" />
          <Stack px="16px">
            <Text category="body-medium">
              Points in your wallet can be used as a discount on any new order placed with
              {' '}
              {retailer.name}. 1 point = $1.00
            </Text>
            <VSpacer size="5" />
          </Stack>
          <Divider />
          <VSpacer size="5" />
          {!!formattedUpdateDate && (
            <Stack alignItems="center">
              <Text category="body-xsmall">
                Updated {formattedUpdateDate}
              </Text>
            </Stack>
          )}
          <VSpacer size="5" />
          {cardWallets.length ? (
            WalletCards
          ) : (
            <Stack alignItems="center" px="24px" py="48px">
              <Text category="body-xlarge">
                0 points
              </Text>
              <VSpacer size="3" />
              <Text category="body-medium"  sx={{ color: theme.palette.grey[700] }}>
                Place an order to start earning points!
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
      {showSellerModal && !!salespersons && (
        <SellerModal
          onClose={() => setShowSellerModal(false)}
          salespersons={salespersons}
        />
      )}
      {!!selectedWallet &&  (
        <RedeemableProductsModal
          manufacturer={selectedWallet.manufacturer}
          onClose={() => setSelectedWallet(undefined)}
          productsForRedeemingPoints={
            selectedWallet.promotion?.productsForRedeemingPoints ?? []
          }
          retailer={retailer}
          sponsorType={
            selectedWallet.manufacturerId ? SponsorType.Manufacturer : SponsorType.Retailer
          }
        />
      )}
    </>
  );
};

interface SellerModalProps {
  onClose: () => void,
  salespersons: Salesperson[],
}

const SellerModal = ({
  onClose,
  salespersons,
}: SellerModalProps) => (
  <DashboardModal
    hideCloseButtonIcon
    onClose={onClose}
    open
    testID="seller-modal"
    title="Select a seller"
  >
    {salespersons.map((salesperson) => (
      <SellerCard
        key={salesperson.id}
        salesperson={salesperson}
      />
    ))}
    <Divider />
  </DashboardModal>
);

interface SellerCardProps {
  salesperson: Salesperson,
}

const SellerCard = ({
  salesperson,
}: SellerCardProps) => {
  const theme = useTheme();
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" p="16px">
      <Stack>
        {!!salesperson.userLocation && (
          <>
            <Text category="body-small" sx={{ color: theme.palette.grey[600] }}>
              {salesperson.userLocation.name}
            </Text>
            <VSpacer size="1" />
          </>
        )}
        <Text category="title-medium">
          {salesperson.businessName}
        </Text>
        {!!salesperson.telephone && (
          <Text category="body-medium" sx={{ color: theme.palette.grey[700] }}>
            {formatPhoneNumber(salesperson.telephone, 'paren')}
          </Text>
        )}
      </Stack>
      <HSpacer size="5" />
      <Button
        color="inherit"
        disabled={!salesperson.telephone}
        href={`sms:${salesperson.telephone}`}
        startIcon={<ChatBubbleOutline />}
        sx={{ borderColor: theme.palette.grey[300] }}
        testID={`message-seller-button-${salesperson.id}`}
        variant="outlined"
      >
        Message
      </Button>
    </Stack>
  );
};
