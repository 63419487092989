import { Box, Stack, SxProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import SwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';
import React, { CSSProperties, ReactNode } from 'react';
import { IconButton, Text, VSpacer } from '..';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import Close from '@mui/icons-material/Close';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';

export interface BottomSheetProps extends SwipeableDrawerProps {
  children?: ReactNode,
  desktop?: boolean, // For testing only!!!
  footer?: ReactNode,
  pullerSx?: SxProps<Theme>,
  showCloseButton?: boolean,
  showPuller?: boolean,
  showFooter?: boolean,
  style?: CSSProperties,
  testID: string,
  title?: string,
}

const Puller = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 3,
  boxShadow: '0px -3px 6px 0px rgba(0, 0, 0, 0.5) inset',
  height: 4,
  left: 'calc(50% - 15px)',
  marginBottom: 16,
  opacity: .4,
  position: 'absolute',
  top: 10,
  width: 32,
}));

const styles: SXStyles = {
  closeButton: { position: 'absolute', top: '16px', right: '16px' },
};

export const BottomSheet = ({
  children,
  desktop = false,
  footer,
  onClose,
  onOpen,
  open,
  pullerSx,
  showCloseButton,
  showPuller = true,
  showFooter = true,
  style,
  testID,
  title,
}: BottomSheetProps) => {
  const Only = desktop ? DesktopOnly : MobileOnly;

  return (
    <Only>
      <SwipeableDrawer
        PaperProps={{
          elevation: 0,
          style: {
            backgroundColor: '#FEFEFE',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            maxHeight: 'calc(100% - 72px)',
            ...style,
          },
        }}
        anchor="bottom"
        data-testid={testID}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        sx={{ zIndex: 1300 }}
      >
        <Stack overflow="hidden">
          {showCloseButton && (
            <IconButton
              onClick={onClose}
              sx={styles.closeButton}
              testID={`${testID}-close-button`}
            >
              <Close />
            </IconButton>
          )}
          {showPuller && (
            <>
              <VSpacer size="8" />
              <Puller onClick={onClose} onDrag={onClose} sx={pullerSx} />
            </>
          )}
          {title && (
            <>
              <Text
                category="title-large"
                sx={{ flexGrow: 1, textAlign: "center" }}
              >
                {title}
              </Text>
              <VSpacer size="3" />
            </>
          )}
          {children}
          {footer || showFooter && <VSpacer size="8" />}
        </Stack>
      </SwipeableDrawer>
    </Only>
  );
};
