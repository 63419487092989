import { Routes } from '@/constants/Routes';
import { CreateAccountPage } from '@/pages/Auth/CreateAccountPage';
import FarmerForgotPasswordForm from '@/pages/Auth/FarmerForgotPasswordForm';
import { LandingPage } from '@/pages/Auth/LandingPage';
import { LoginPage } from '@/pages/Auth/LoginPage';
import { BenefitsPage } from '@/pages/CustomerDashboard/BenefitsPage';
import { CustomerDashboard } from '@/pages/CustomerDashboard/CustomerDashboard';
import { HowItWorksPage } from '@/pages/CustomerDashboard/HowItWorksPage';
import { PromotionsPage } from '@/pages/CustomerDashboard/Promotions/PromotionsPage';
import { WalletPage } from '@/pages/CustomerDashboard/WalletPage';
import { SplashPage } from '@/pages/RedirectToOs/SplashPage';
import { WebDeliverable } from '@/pages/WebDeliverable/WebDeliverable';
import { GetAppPage } from '@/views/GetAppPage';
import { LoginLayout, PasswordLayout } from '@/views/Layouts';
import { UnderConstruction } from '@/views/UnderConstruction';
import React from 'react';
import { Navigate, Route, Routes as AppRoutes } from "react-router-dom";
import SetPasswordForm from '../pages/Auth/SetPasswordForm';

export const LoginView = () => {
  return (
    <AppRoutes>
      <Route element={<LandingPage />} path={Routes.LANDING} />
      <Route element={<LoginPage />} path={Routes.LOGIN} />
      <Route
        element={<CreateAccountPage />}
        path={Routes.CREATE_ACCOUNT}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm /></PasswordLayout>}
        path={Routes.CREATE_PASSWORD}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm termsAndConditions /></PasswordLayout>}
        path={Routes.SET_RETAILER_PASSWORD}
      />
      <Route
        element={<PasswordLayout><SetPasswordForm isResetPasswordForm /></PasswordLayout>}
        path={Routes.RESET_PASSWORD}
      />
      <Route
        element={<LoginLayout><FarmerForgotPasswordForm /></LoginLayout>}
        path={Routes.FORGOT_PASSWORD}
      />
      <Route
        element={<FarmerForgotPasswordForm />}
        path={Routes.FORGOT_PASSWORD}
      />
      <Route element={<WebDeliverable />}
        path={Routes.WEB_DELIVERABLE}
      />
      <Route element={<UnderConstruction />} path={Routes.UNDER_CONSTRUCTION} />
      {
        [
          Routes.APP_RECOMMENDATION,
          Routes.APP_REQUEST,
          Routes.RETAILER_APP_RECOMMENDATION,
          Routes.RETAILER_APP_REQUEST,
        ].map((path) => (
          <Route element={<SplashPage />} key={path} path={path} />
        ))
      }
      <Route element={<GetAppPage />} path={Routes.GET_APP} />
      <Route element={<Navigate to={Routes.LANDING} />} path={"*"} />
      <Route element={<CustomerDashboard />}
             path={Routes.CUSTOMER_DASHBOARD}
      />
        <Route
          element={<BenefitsPage />}
          path={Routes.CUSTOMER_DASHBOARD_BENEFITS}
        />
        <Route
          element={<HowItWorksPage />}
          path={Routes.CUSTOMER_DASHBOARD_HOW_IT_WORKS}
        />
      <Route
        element={<WalletPage />}
        path={Routes.CUSTOMER_DASHBOARD_WALLET}
      />
      <Route
        element={<PromotionsPage />}
        path={Routes.CUSTOMER_DASHBOARD_PROMOTIONS}
      />
    </AppRoutes>
  );
};
