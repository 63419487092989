import { Button, Dialog, Text, VSpacer } from '@/components/DesignSystem';
import { ApiRetailer } from '@api/interfaces';
import React from 'react';

interface HowToEarnPointsModalProps {
  grantBoth: boolean,
  onClose: () => void,
  retailer: ApiRetailer,
}

export const HowToEarnPointsModal = ({
  grantBoth,
  onClose,
  retailer,
}: HowToEarnPointsModalProps) => (
  <Dialog
    cancelButton={(props) => (
      <Button
        onClick={onClose}
        sx={{ color: retailer.brandColor }}
        variant="text"
        {...props}
      >
        Close
      </Button>
    )}
    onClose={onClose}
    open
    showCloseButton={false}
    testID="how-to-earn-points-modal"
    title="How to earn these points"
  >
    <Text category="body-medium" sx={{ color: '#525252' }}>
      Eligible purchases earn points to be redeemed on future orders.
    </Text>
    <VSpacer size="5" />
    <Text category="body-medium" sx={{ color: '#525252' }}>
      {grantBoth
        ? `You will earn both ${retailer.name} and manufacturer promotion points.
          Exclusions may apply. See promotion for qualifying products.`
        : `If a promotion is running on the product, you will only earn the promotion loyalty
          points. Otherwise, you will earn ${retailer.name} points.`
      }
    </Text>
  </Dialog>
);
